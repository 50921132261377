/*
  ⚠️ IMPORTANT NOTICE ⚠️

    This is only a backup constant, use the hook to get the latest categories

    import { useCategories } from 'hooks/categories'

*/

export const CATEGORIES_BACKUP = {
  us: [
    {
      name: 'Appliances',
      code: 'appliances',
      variants: ['Aparatos'],
      supported: true
    },
    {
      name: 'Arts, Crafts & Sewing',
      code: 'arts-crafts',
      variants: [
        'Arts Crafts & Sewing',
        'Arte y Manualidades',
        'Arte, Manualidades y Costura'
      ],
      supported: true
    },
    {
      name: 'Automotive',
      code: 'automotive',
      variants: ['Automotive Parts & Accessories', 'Auto', 'Automotriz'],
      supported: true
    },
    {
      name: 'Baby',
      code: 'baby-products',
      variants: ['Baby Products', 'Bebé', 'Bebés'],
      supported: true
    },
    {
      name: 'Beauty & Personal Care',
      code: 'beauty',
      variants: ['Belleza y Cuidado Personal', 'Belleza'],
      supported: true
    },
    {
      name: 'Camera & Photo',
      code: 'photo',
      variants: ['Camera & Photo Products', 'Cámaras y Fotografía'],
      hidden: true,
      supported: true
    },
    {
      name: 'Cell Phones & Accessories',
      code: 'wireless',
      variants: ['Celulares y Accesorios'],
      supported: true
    },
    {
      name: 'Clothing, Shoes & Jewelry',
      code: 'fashion',
      variants: ['Clothing Shoes & Jewelry', 'Ropa, Zapatos y Joyería'],
      supported: true
    },
    {
      name: 'Computers & Accessories',
      code: 'pc',
      variants: ['Computadoras y Accesorios'],
      hidden: true,
      supported: true
    },
    {
      name: 'Electronics',
      code: 'electronics',
      variants: ['Electrónica'],
      supported: true
    },
    {
      name: 'Grocery & Gourmet Food',
      code: 'grocery',
      variants: ['Comida Gourmet y Alimentos'],
      supported: true
    },
    {
      name: 'Health & Household',
      code: 'hpc',
      variants: ['Salud y Hogar'],
      supported: true
    },
    {
      name: 'Home & Kitchen',
      code: 'home-garden',
      variants: ['Hogar y Cocina'],
      supported: true
    },
    {
      name: 'Industrial & Scientific',
      code: 'industrial',
      variants: ['Industrial y Científico'],
      supported: true
    },
    {
      name: 'Kitchen & Dining',
      code: 'kitchen',
      variants: ['Cocina y Comedor'],
      hidden: true,
      supported: true
    },
    {
      name: 'Musical Instruments',
      code: 'musical-instruments',
      variants: ['Instrumentos Musicales'],
      supported: true
    },
    {
      name: 'Office Products',
      code: 'office-products',
      variants: ['Productos de Oficina'],
      supported: true
    },
    {
      name: 'Patio, Lawn & Garden',
      code: 'lawn-garden',
      variants: ['Patio, Césped y Jardín'],
      supported: true
    },
    {
      name: 'Pet Supplies',
      code: 'pet-supplies',
      variants: ['Productos para Animales'],
      supported: true
    },
    {
      name: 'Sports & Outdoors',
      code: 'sporting-goods',
      variants: ['Deportes y Actividades al Aire Libre'],
      supported: true
    },
    {
      name: 'Tools & Home Improvement',
      code: 'hi',
      variants: ['Herramientas y Mejoras del Hogar'],
      supported: true
    },
    {
      name: 'Toys & Games',
      code: 'toys-and-games',
      variants: ['Juguetes y Juegos'],
      supported: true
    }
  ],
  ca: [
    {
      name: 'Automotive',
      code: 'automotive',
      variants: ['Auto'],
      supported: true
    },
    {
      name: 'Baby',
      code: 'baby',
      variants: ['Bébé et Puériculture'],
      supported: true
    },
    {
      name: 'Beauty & Personal Care',
      code: 'beauty',
      variants: ['Beauté'],
      supported: true
    },
    {
      name: 'Clothing & Accessories',
      display_name: 'Clothing, Shoes & Accessories',
      code: 'fashion',
      variants: ['Vêtements et Accessoires', 'Mode'],
      supported: true
    },
    {
      name: 'Electronics',
      code: 'electronics',
      variants: ['Électronique'],
      supported: true
    },
    {
      name: 'Grocery & Gourmet Food',
      code: 'grocery',
      variants: ['Épicerie et Cuisine gastronomique'],
      supported: true
    },
    {
      name: 'Health & Personal Care',
      code: 'hpc',
      variants: ['Santé et Soins personnels'],
      supported: true
    },
    {
      name: 'Home',
      code: 'kitchen',
      variants: ['Home & Kitchen', 'Maison'],
      supported: true
    },
    {
      name: 'Industrial & Scientific',
      code: 'industrial',
      variants: ['Commerce, Industrie et Science'],
      supported: true
    },
    {
      name: 'Jewelry',
      display_name: 'Jewellery',
      code: 'jewelry',
      variants: ['Bijoux'],
      hidden: true,
      supported: true
    },
    {
      name: 'Luggage & Bags',
      code: null,
      variants: ['Bagages et sacs'],
      hidden: true,
      supported: true
    },
    {
      name: 'Musical Instruments, Stage & Studio',
      code: 'musical-instruments',
      variants: ['Instruments de musique, scène et studio'],
      supported: true
    },
    {
      name: 'Office Products',
      code: 'office',
      variants: ['Fournitures pour le bureau'],
      supported: true
    },
    {
      name: 'Patio, Lawn & Garden',
      code: 'lawn-garden',
      variants: ['Terrasse et Jardin'],
      supported: true
    },
    {
      name: 'Pet Supplies',
      code: 'pet-supplies',
      variants: ['Animalerie'],
      supported: true
    },
    {
      name: 'Shoes & Handbags',
      code: 'shoes',
      variants: ['Chaussures et Sacs à main'],
      hidden: true,
      supported: true
    },
    {
      name: 'Sports & Outdoors',
      code: 'sports',
      variants: ['Sports et Plein air'],
      supported: true
    },
    {
      name: 'Tools & Home Improvement',
      code: 'hi',
      variants: ['Outils et Bricolage'],
      supported: true
    },
    {
      name: 'Toys & Games',
      code: 'toys',
      variants: ['Jeux et Jouets'],
      supported: true
    },
    {
      name: 'Watches',
      code: 'watch',
      variants: ['Montres'],
      supported: true
    }
  ],
  mx: [
    {
      name: 'Bebé',
      code: 'baby',
      variants: [],
      supported: true
    },
    {
      name: 'Deportes y Aire Libre',
      code: 'sports',
      variants: [],
      supported: true
    },
    {
      name: 'Electrónicos',
      code: 'electronics',
      variants: [],
      supported: true
    },
    {
      name: 'Herramientas y Mejoras del Hogar',
      code: 'tools',
      variants: [],
      supported: true
    },
    {
      name: 'Hogar y Cocina',
      code: 'kitchen',
      variants: [],
      supported: true
    },
    {
      name: 'Industria, Empresas y Ciencia',
      code: 'industrial',
      variants: [],
      supported: true
    },
    {
      name: 'Instrumentos Musicales',
      code: 'musical-instruments',
      variants: [],
      supported: true
    },
    {
      name: 'Juguetes y Juegos',
      code: 'toys',
      variants: [],
      supported: true
    },
    {
      name: 'Oficina y papelería',
      code: 'officeproduct',
      variants: [],
      supported: true
    },
    {
      name: 'Ropa, Zapatos y Accesorios',
      code: 'shoes',
      variants: [],
      supported: true
    },
    {
      name: 'Salud y Cuidado Personal',
      code: 'hpc',
      variants: [],
      supported: true
    }
  ],
  uk: [
    {
      name: 'Automotive',
      code: 'automotive',
      variants: [],
      supported: true
    },
    {
      name: 'Baby Products',
      code: 'baby',
      variants: ['Baby'],
      supported: true
    },
    {
      name: 'Beauty',
      code: 'beauty',
      variants: [],
      supported: true
    },
    {
      name: 'Business, Industry & Science',
      code: 'industrial',
      variants: [],
      supported: true
    },
    {
      name: 'Computers & Accessories',
      code: 'computers',
      variants: [],
      supported: true
    },
    {
      name: 'DIY & Tools',
      code: 'diy',
      variants: [],
      supported: true
    },
    {
      name: 'Electronics & Photo',
      code: 'electronics',
      variants: [],
      supported: true
    },
    {
      name: 'Clothing',
      display_name: 'Fashion',
      code: 'fashion',
      variants: [],
      supported: false
    },
    {
      name: 'Garden & Outdoors',
      display_name: 'Garden',
      code: 'outdoors',
      variants: ['Garden', 'Gardening'],
      supported: true
    },
    {
      name: 'Grocery',
      code: 'grocery',
      variants: [],
      supported: true
    },
    {
      name: 'Health & Personal Care',
      code: 'drugstore',
      variants: [],
      supported: true
    },
    {
      name: 'Home & Kitchen',
      code: 'kitchen',
      variants: [],
      supported: true
    },
    {
      name: 'Jewellery',
      code: 'jewelry',
      variants: [],
      hidden: true,
      supported: true
    },
    {
      name: 'Large Appliances',
      code: 'appliances',
      variants: [],
      supported: true
    },
    {
      name: 'Lighting',
      code: 'lighting',
      variants: [],
      supported: true
    },
    {
      name: 'Luggage',
      code: 'luggage',
      variants: [],
      supported: true
    },
    {
      name: 'Musical Instruments & DJ',
      code: 'musical-instruments',
      variants: [],
      supported: true
    },
    {
      name: 'Pet Supplies',
      code: 'pet-supplies',
      variants: [],
      supported: true
    },
    {
      name: 'Shoes & Bags',
      code: 'shoes',
      variants: [],
      hidden: true,
      supported: true
    },
    {
      name: 'Sports & Outdoors',
      code: 'sports',
      variants: [],
      supported: true
    },
    {
      name: 'Stationery & Office Supplies',
      code: 'officeproduct',
      variants: [],
      supported: true
    },
    {
      name: 'Toys & Games',
      code: 'kids',
      variants: [],
      supported: true
    },
    {
      name: 'Watches',
      code: 'watch',
      variants: [],
      hidden: true,
      supported: true
    }
  ],
  it: [
    {
      name: 'Abbigliamento',
      display_name: 'Moda',
      code: 'fashion',
      variants: ['Moda'],
      supported: false
    },
    {
      name: 'Alimentari e cura della casa',
      code: 'grocery',
      variants: [],
      supported: true
    },
    {
      name: 'Auto e Moto',
      code: 'automotive',
      variants: [],
      supported: true
    },
    {
      name: 'Bellezza',
      code: 'beauty',
      variants: [],
      supported: true
    },
    {
      name: 'Casa e cucina',
      code: 'kitchen',
      variants: [],
      supported: true
    },
    {
      name: 'Commercio, Industria e Scienza',
      code: 'industrial',
      variants: [],
      supported: true
    },
    {
      name: 'Elettronica',
      code: 'electronics',
      variants: [],
      supported: true
    },
    {
      name: 'Fai da te',
      code: 'tools',
      variants: [],
      supported: true
    },
    {
      name: 'Giardino e giardinaggio',
      code: 'garden',
      variants: [],
      supported: true
    },
    {
      name: 'Giochi e giocattoli',
      code: 'toys',
      variants: [],
      supported: true
    },
    {
      name: 'Gioielli',
      code: 'jewelry',
      variants: [],
      supported: true
    },
    {
      name: 'Illuminazione',
      code: 'lighting',
      variants: [],
      supported: true
    },
    {
      name: 'Informatica',
      code: 'pc',
      variants: [],
      supported: true
    },
    {
      name: 'Orologi',
      code: 'watch',
      variants: [],
      supported: true
    },
    {
      name: 'Prima infanzia',
      code: 'baby',
      variants: [],
      supported: true
    },
    {
      name: 'Salute e cura della persona',
      code: 'hpc',
      variants: [],
      supported: true
    },
    {
      name: 'Scarpe e borse',
      code: 'shoes',
      variants: [],
      supported: true
    },
    {
      name: 'Sport e tempo libero',
      code: 'sports',
      variants: [],
      supported: true
    },
    {
      name: 'Valigeria',
      code: 'luggage',
      variants: [],
      supported: true
    }
  ],
  es: [
    {
      name: 'Bebé',
      code: 'baby',
      variants: [],
      supported: true
    },
    {
      name: 'Belleza',
      code: 'beauty',
      variants: ['Beleza'],
      supported: true
    },
    {
      name: 'Bricolaje y herramientas',
      code: 'tools',
      variants: ['Bricolage e Ferramentas'],
      supported: true
    },
    {
      name: 'Coche y moto',
      code: 'automotive',
      variants: ['Automóvel'],
      supported: true
    },
    {
      name: 'Deportes y aire libre',
      code: 'sports',
      variants: ['Produtos Desportivos'],
      supported: true
    },
    {
      name: 'Electrónica',
      code: 'electronics',
      variants: ['Eletrónica'],
      supported: true
    },
    {
      name: 'Equipaje',
      code: 'luggage',
      variants: ['Bagagem'],
      hidden: true,
      supported: true
    },
    {
      name: 'Hogar y cocina',
      code: 'kitchen',
      variants: ['Casa e Cozinha'],
      supported: true
    },
    {
      name: 'Iluminación',
      code: 'lighting',
      variants: ['Iluminação'],
      supported: true
    },
    {
      name: 'Industria, empresas y ciencia',
      code: 'industrial',
      variants: ['Industrial e Científico'],
      supported: true
    },
    {
      name: 'Informática',
      code: 'computers',
      variants: ['Computadores'],
      supported: true
    },
    {
      name: 'Instrumentos musicales',
      code: 'musical-instruments',
      variants: ['Instrumentos Musicais'],
      supported: true
    },
    {
      name: 'Jardín',
      code: 'lawn-garden',
      variants: ['Pátio, Relvado e Jardim'],
      supported: true
    },
    {
      name: 'Joyería',
      code: 'jewelry',
      variants: ['Joalheiros'],
      hidden: true,
      supported: true
    },
    {
      name: 'Juguetes y juegos',
      code: 'toys',
      variants: ['Brinquedos e Jogos'],
      supported: true
    },
    {
      name: 'Moda',
      code: 'fashion',
      variants: [],
      supported: false
    },
    {
      name: 'Oficina y papelería',
      code: 'office',
      variants: ['Produtos para Escritório'],
      supported: true
    },
    {
      name: 'Relojes',
      code: 'watch',
      variants: ['Relógios'],
      hidden: true,
      supported: true
    },
    {
      name: 'Salud y cuidado personal',
      code: 'hpc',
      variants: ['Saúde e Casa'],
      supported: true
    },
    {
      name: 'Zapatos y complementos',
      code: 'shoes',
      variants: [],
      hidden: true,
      supported: true
    }
  ],
  fr: [
    {
      name: 'Animalerie',
      code: 'pet-supplies',
      variants: [],
      supported: true
    },
    {
      name: 'Auto & Moto',
      display_name: 'Auto et Moto',
      code: 'automotive',
      variants: [],
      supported: true
    },
    {
      name: 'Bagages',
      code: 'luggage',
      variants: [],
      hidden: true,
      supported: true
    },
    {
      name: 'Beauté & Parfum',
      display_name: 'Beauté et Parfum',
      code: 'beauty',
      variants: ['Beaut et Parfum'],
      supported: true
    },
    {
      name: 'Bébé & Puériculture',
      display_name: 'Bébé et Puériculture',
      code: 'baby',
      variants: [],
      supported: true
    },
    {
      name: 'Bijoux',
      code: 'jewelry',
      variants: [],
      supported: true
    },
    {
      name: 'Bricolage',
      code: 'hi',
      variants: [],
      supported: true
    },
    {
      name: 'Chaussures & Sacs',
      display_name: 'Chaussures et Sacs',
      code: null,
      variants: [],
      supported: true
    },
    {
      name: 'Commerce, Industrie & Science',
      display_name: 'Commerce, Industrie et Science',
      code: 'industrial',
      variants: [],
      supported: true
    },
    {
      name: 'Cuisine & Maison',
      display_name: 'Cuisine et Maison',
      code: 'kitchen',
      variants: [],
      supported: true
    },
    {
      name: 'Epicerie',
      code: 'grocery',
      variants: [],
      supported: true
    },
    {
      name: 'Fournitures de bureau',
      code: 'officeproduct',
      variants: [],
      supported: true
    },
    {
      name: 'Gros électroménager',
      code: 'appliances',
      variants: [],
      supported: true
    },
    {
      name: 'High-Tech',
      code: 'electronics',
      variants: [],
      supported: true
    },
    {
      name: 'Hygiène & Santé',
      display_name: 'Hygiène et Santé',
      code: 'hpc',
      variants: [],
      supported: true
    },
    {
      name: 'Informatique',
      code: 'computers',
      variants: [],
      supported: true
    },
    {
      name: 'Instruments de musique & Sono',
      display_name: 'Instruments de musique et Sono',
      code: 'musical-instruments',
      variants: [],
      supported: true
    },
    {
      name: 'Jardin',
      code: 'lawn-garden',
      variants: [],
      supported: true
    },
    {
      name: 'Jeux & Jouets',
      display_name: 'Jeux et Jouets',
      code: 'toys',
      variants: [],
      supported: true
    },
    {
      name: 'Luminaires & Eclairage',
      display_name: 'Luminaires et Éclairage',
      code: 'lighting',
      variants: [],
      supported: true
    },
    {
      name: 'Mode',
      code: 'fashion',
      variants: [],
      supported: false
    },
    {
      name: 'Montres',
      code: 'watch',
      variants: [],
      supported: true
    },
    {
      name: 'Sports & Loisirs',
      display_name: 'Sports et Loisirs',
      code: 'sports',
      variants: [],
      supported: true
    },
    {
      name: 'Vêtements',
      display_name: 'Mode',
      code: 'apparel',
      variants: ['Mode'],
      supported: false
    }
  ],
  de: [
    {
      name: 'Auto & Motorrad',
      code: 'automotive',
      variants: [
        'Automotive',
        'Auto moto',
        'Auto & motor',
        'Motoryzacja',
        'Otomotiv',
        'Bildele og tilbehør'
      ],
      supported: true
    },
    {
      name: 'Baby',
      code: 'baby',
      variants: [
        'Baby Products',
        'Produkty pro nejmladší děti',
        'Babyproducten',
        'Produkty dziecięce',
        'Bebek',
        'Babyprodukter'
      ],
      supported: true
    },
    {
      name: 'Baumarkt',
      code: 'diy',
      variants: [
        'DIY & Tools',
        'Kutilové a nástroje',
        'Klussen & gereedschap',
        'Narzędzia i renowacja domu',
        'Yapı Market',
        'DIY og værktøj'
      ],
      supported: true
    },
    {
      name: 'Beauty',
      display_name: 'Kosmetik',
      code: 'beauty',
      variants: [
        'Kosmetik',
        'Beauty Products',
        'Krása',
        'Uroda',
        'Güzellik',
        'Skønhed'
      ],
      supported: true
    },
    {
      name: 'Bekleidung',
      display_name: 'Fashion',
      code: 'apparel',
      variants: [
        'Fashion',
        'Apparel',
        'Oblečení',
        'Kleding',
        'Odzież',
        'Giyim',
        'Beklædning'
      ],
      supported: false
    },
    {
      name: 'Beleuchtung',
      code: 'lighting',
      variants: [
        'Lighting',
        'Osvětlení',
        'Verlichting',
        'Oświetlenie',
        'Aydınlatma',
        'Belysning'
      ],
      supported: true
    },
    {
      name: 'Bürobedarf & Schreibwaren',
      code: 'officeproduct',
      variants: [
        'Stationery & Office Supplies',
        'Papírenské a kancelářské potřeby',
        'Kantoorproducten',
        'Artykuły papiernicze i biurowe',
        'Kırtasiye ve Ofis Malzemeleri',
        'Papirvarer & kontorartikler'
      ],
      supported: true
    },
    {
      name: 'Computer & Zubehör',
      code: 'computers',
      variants: [
        'Computer & Accessories',
        'Počítače a příslušenství',
        'Computers & accessoires',
        'Komputery i akcesoria',
        'Bilgisayar ve Aksesuarları',
        'Computer & tilbehør'
      ],
      supported: true
    },
    {
      name: 'Drogerie & Körperpflege',
      code: 'drugstore',
      variants: [
        'Health & Personal Care',
        'Péče o zdraví a tělo',
        'Gezondheid & persoonlijke verzorging',
        'Zdrowie i higiena osobista',
        'Sağlık ve Kişisel Bakım',
        'Sundhed og personlig pleje'
      ],
      supported: true
    },
    {
      name: 'Elektro-Großgeräte',
      code: 'appliances',
      variants: [
        'Large Appliances',
        'Velké spotřebiče',
        'Grote apparaten',
        'Duże AGD',
        'Beyaz Eşyalar',
        'Apparater'
      ],
      supported: true
    },
    {
      name: 'Elektronik & Foto',
      code: 'ce-de',
      variants: [
        'Electronics & Photo',
        'Elektronika a fototechnika',
        'Elektronica',
        'Elektronika i fotografia',
        'Elektronik ve Fotoğraf',
        'Elektronik & Foto'
      ],
      supported: true
    },
    {
      name: 'Garten',
      code: 'garden',
      variants: [
        'Garden',
        'Zahrada',
        'Tuin, terras & gazon',
        'Ogród',
        'Bahçe',
        'Have'
      ],
      supported: true
    },
    {
      name: 'Gewerbe, Industrie & Wissenschaft',
      code: 'industrial',
      variants: [
        'Business, Industry & Science',
        'Byznys, průmysl a věda',
        'Zakelijk, industrie & wetenschap',
        'Biznes, przemysł i nauka',
        'İş, Endüstri ve Bilim',
        'Virksomheder, industri og videnskab'
      ],
      supported: true
    },
    {
      name: 'Haustier',
      code: 'pet-supplies',
      variants: [
        'Pet Supplies',
        'Chovatelské potřeby',
        'Huisdierbenodigdheden',
        'Artykuły dla zwierząt',
        'Evcil Hayvan Ürünleri',
        'Varer til kæledyr'
      ],
      supported: true
    },
    {
      name: 'Kamera & Foto',
      code: 'photo',
      variants: [
        'Camera & Photo',
        'Kamery a fotoaparáty',
        'Camera & foto',
        'Aparaty i fotografia',
        'Kameralar ve Fotoğraf Makineleri',
        'Kamera- & fotoprodukter'
      ],
      supported: true
    },
    {
      name: 'Koffer, Rucksäcke & Taschen',
      code: 'luggage',
      variants: [
        'Luggage & Travel Gear',
        'Zavazadla',
        'Bagage & reisbenodigheden',
        'Bagaż',
        'Çanta',
        'Bagage og rejsegear'
      ],
      hidden: true,
      supported: true
    },
    {
      name: 'Küche, Haushalt & Wohnen',
      code: 'kitchen',
      variants: [
        'Home & Kitchen',
        'Kuchyně a domácnost',
        'Wonen & keuken',
        'Dom i kuchnia',
        'Ev ve Yaşam',
        'Bolig og køkken'
      ],
      supported: true
    },
    {
      name: 'Lebensmittel & Getränke',
      code: 'grocery',
      variants: [
        'Grocery',
        'Potraviny',
        'Levensmiddelen',
        'Artykuły spożywcze',
        'Gıda Ürünleri',
        'Købmand'
      ],
      supported: true
    },
    {
      name: 'Musikinstrumente & DJ-Equipment',
      code: 'musical-instruments',
      variants: [
        'Musical Instruments & DJ',
        'Hudební nástroje a vybavení pro DJ',
        'Muziekinstrumenten',
        'Instrumenty muzyczne i sprzęt dla DJ-ów',
        'Müzik Enstrümanları ve DJ',
        'Musikinstrumenter & DJ'
      ],
      supported: true
    },
    {
      name: 'Schmuck',
      code: 'jewelry',
      variants: [
        'Jewelry',
        'Šperky',
        'Juwelen',
        'Biżuteria',
        'Takı',
        'Smykker'
      ],
      supported: true
    },
    {
      name: 'Schuhe & Handtaschen',
      code: 'shoes',
      variants: [
        'Shoes & Handbags',
        'Obuv',
        'Schoenen en tassen',
        'Buty i torebki',
        'Ayakkabılar ve Çantalar',
        'Sko og håndtasker'
      ],
      hidden: true,
      supported: true
    },
    {
      name: 'Spielzeug',
      code: 'toys',
      variants: [
        'Toys',
        'Hračky a hry',
        'Speelgoed',
        'Zabawki i gry',
        'Oyuncaklar ve Oyunlar',
        'Legetøj'
      ],
      supported: true
    },
    {
      name: 'Sport & Freizeit',
      code: 'sports',
      variants: [
        'Sports & Outdoors',
        'Sport a venkovní aktivity',
        'Sport & outdoor',
        'Sport i turystyka',
        'Spor ve Açık Hava Ürünleri',
        'Sport og friluftsliv'
      ],
      supported: true
    },
    {
      name: 'Uhren',
      code: 'watch',
      variants: [
        'Watches',
        'Hodinky',
        'Horloges',
        'Zegarki',
        'Kol Saatleri',
        'Ure'
      ],
      supported: true
    }
  ],
  in: [
    {
      name: 'Baby Products',
      code: 'baby',
      variants: ['Baby', 'बेबी के सामान'],
      supported: true
    },
    {
      name: 'Bags, Wallets and Luggage',
      code: 'luggage',
      variants: ['Bags, Wallets & Luggage', 'बैग, वॉलेट और लगेज'],
      supported: true
    },
    {
      name: 'Beauty',
      code: 'beauty',
      variants: ['ब्यूटी'],
      supported: true
    },
    {
      name: 'Car & Motorbike',
      code: 'automotive',
      variants: ['कार और मोटरबाइक', 'Automotive'],
      supported: true
    },
    {
      name: 'Clothing & Accessories',
      code: 'apparel',
      variants: ['कपड़े और एक्सेसरीज़'],
      supported: true
    },
    {
      name: 'Electronics',
      code: 'electronics',
      variants: ['इलेक्ट्रॉनिक्स'],
      supported: true
    },
    {
      name: 'Grocery & Gourmet Foods',
      code: 'grocery',
      variants: ['ग्रॉसरी और गूरमे फ़ूड'],
      supported: true
    },
    {
      name: 'Health & Personal Care',
      code: 'hpc',
      variants: ['स्वास्थ्य और व्यक्तिगत देखभाल'],
      supported: true
    },
    {
      name: 'Home & Kitchen',
      code: 'kitchen',
      variants: ['घर और किचन'],
      supported: true
    },
    {
      name: 'Industrial & Scientific',
      code: 'industrial',
      variants: ['इंडस्ट्रियल और साइंटिफ़िक'],
      supported: true
    },
    {
      name: 'Jewellery',
      code: 'jewelry',
      variants: ['ज्वेलरी'],
      supported: true
    },
    {
      name: 'Musical Instruments',
      code: 'musical-instruments',
      variants: ['म्यूज़िकल इंस्ट्रूमेंट'],
      supported: true
    },
    {
      name: 'Office Products',
      code: 'office',
      variants: ['कार्यालय का सामान'],
      supported: true
    },
    {
      name: 'Pet Supplies',
      code: 'pet-supplies',
      variants: ['पालतू पशुओं का सामान'],
      supported: true
    },
    {
      name: 'Shoes & Handbags',
      code: 'shoes',
      variants: ['शूज़ और हैंडबैग्स'],
      supported: true
    },
    {
      name: 'Sports, Fitness & Outdoors',
      code: 'sports',
      variants: ['खेल, फ़िटनेस और आउटडोर'],
      supported: true
    },
    {
      name: 'Toys & Games',
      code: 'toys',
      variants: ['टॉयज़ और गेम्स'],
      supported: true
    },
    {
      name: 'Watches',
      code: 'watches',
      variants: ['घड़ियां'],
      supported: true
    }
  ],
  jp: [
    {
      name: 'DIY・工具・ガーデン',
      code: 'diy',
      variants: ['DIY, Tools & Garden', '家装及园艺的'],
      supported: true
    },
    {
      name: 'おもちゃ',
      code: 'toys',
      variants: ['Toys & Games', '玩具和游戏的'],
      supported: true
    },
    {
      name: 'シューズ&バッグ',
      code: null,
      variants: ['Shoes & Bags', '鞋靴、箱包'],
      hidden: true,
      supported: true
    },
    {
      name: 'ジュエリー',
      code: null,
      variants: ['Jewelry', '珠宝首饰'],
      hidden: true,
      supported: true
    },
    {
      name: 'スポーツ&アウトドア',
      code: 'sports',
      variants: ['Sports & Outdoors', '运动户外休闲的'],
      supported: true
    },
    {
      name: 'ドラッグストア',
      code: 'hpc',
      variants: ['Health & Personal Care', '个护健康的'],
      supported: true
    },
    {
      name: 'ビューティー',
      code: 'beauty',
      variants: ['Beauty', '美容化妆的'],
      supported: true
    },
    {
      name: 'ベビー&マタニティ',
      code: 'baby',
      variants: ['Baby', '婴儿用品的'],
      supported: true
    },
    {
      name: 'ペット用品',
      code: 'pet-supplies',
      variants: ['Pet Supplies', '宠物用品的'],
      supported: true
    },
    {
      name: 'ホビー',
      code: 'hobby',
      variants: ['Hobbies', '兴趣爱好的'],
      supported: true
    },
    {
      name: 'ホーム&キッチン',
      code: 'kitchen',
      variants: ['Home & Kitchen', '家居、厨具、家装的'],
      supported: true
    },
    {
      name: '大型家電',
      code: 'appliances',
      variants: ['Large Appliances', '大家电的'],
      supported: true
    },
    {
      name: '家電&カメラ',
      code: 'electronics',
      variants: ['Electronics', '电子的'],
      supported: true
    },
    {
      name: '文房具・オフィス用品',
      code: 'office-products',
      variants: ['Office Products', '办公用品的'],
      supported: true
    },
    {
      name: 'ファッション',
      code: 'fashion',
      variants: [],
      supported: false
    },
    {
      name: '服&ファッション小物',
      code: null,
      variants: ['Clothing & Accessories', '服装＆时尚饰品'],
      hidden: true,
      supported: true
    },
    {
      name: '産業・研究開発用品',
      code: 'industrial',
      variants: ['Industrial & Scientific', '工业与科研用品的'],
      supported: true
    },
    {
      name: '車&バイク',
      code: 'automotive',
      variants: ['Automotive', '汽车及摩托车的'],
      supported: true
    },
    {
      name: '食品・飲料・お酒',
      code: 'food-beverage',
      variants: ['Food, Beverages & Alcohol', '食品的'],
      supported: true
    }
  ]
}
