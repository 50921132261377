export const PRODUCT_DATABASE_CSV_EXPORT_COLUMNS = {
  asin: 'ASIN',
  name: 'Product Name',
  brand: 'Brand',
  calculatedCategory: 'Category',
  estRevenue: 'Est. Monthly Revenue',
  estimatedSales: 'Est. Monthly Sales',
  price: 'Price',
  fees: 'Fees',
  net: 'Net',
  rank: 'Rank',
  nReviews: 'Reviews',
  listingQualityScore: 'LQS',
  nSellers: 'Sellers',
  listedAt: 'Date First Available',
  sellerName: 'Buy Box Owner',
  rating: 'Rating',
  dimensions: 'Dimensions',
  tier: 'Product Tier',
  weight: 'Weight',
  variantDifferences: 'Variant Differences'
}
