import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { TEXT_STYLES } from 'TEXT_STYLES'

import errorBalloonIcon from 'icons/svg/notification-error.svg'

const AccountSyncSuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
  margin: 20px 0;
  padding: 15px 15px 20px 15px;
  img {
    width: 300px;
    height: 150px;
    margin-bottom: 15px;
  }
`
const Title = styled.h1`
  ${TEXT_STYLES.Coaching1};
  color: ${props => props.theme.colors.grey700};
  margin: 0;
`
const Subtitle = styled.h2`
  ${TEXT_STYLES.Coaching2Grey};
  color: ${props => props.theme.colors.grey700};
  font-weight: normal !important;
  padding-top: 15px;
  padding-bottom: 20px;
  margin: 0;
  a {
    color: ${props => props.theme.colors.primary};
  }
`
const HeaderWrapper = styled.div`
  width: 100%;
`
const P = styled.p`
  ${TEXT_STYLES.H4Black}
  margin: 0;
  margin-bottom: 20px;
`
const ErrorWrapper = styled.div`
  ${TEXT_STYLES.BodyRedError}
  min-height: 20px;
  display: flex;
  flex-direction: row;
`
const ErrorMessage = styled.div`
  margin-left: 10px;
`
const ErrorIcon = styled.img`
  align-self: top;
`

const renderErrors = errors => {
  const errorsFormatted = Array.isArray(errors) ? errors : [errors]

  return Array.from(errorsFormatted).map(error => {
    return (
      <ErrorWrapper key={error}>
        <ErrorIcon src={errorBalloonIcon} alt="Error" />
        <ErrorMessage>{error}</ErrorMessage>
      </ErrorWrapper>
    )
  })
}

export const AccountSyncSuccess = ({ type, errors }) => {
  const { t } = useTranslation('mwsSync')
  const isPpc = type === 'ppc'
  const iconLink = isPpc
    ? 'https://djga94vwgu7gt.cloudfront.net/assets/junglescout/ppc-lwa-login@3x.png'
    : 'https://djga94vwgu7gt.cloudfront.net/assets/junglescout/mws-lwa-login@3x.png'

  const message = isPpc
    ? t(
        'mwsSync:AccountSyncSuccess.ppcMessage',
        'Your PPC account is connected! Your PPC data may take up to 24 hours to load...'
      )
    : t(
        'mwsSync:AccountSyncSuccess.header',
        'Your Seller Central account is connected!'
      )

  const header = isPpc
    ? t('mwsSync:AccountSyncSuccess.ppcHeader', 'Connect PPC Data')
    : t('mwsSync:AccountSyncSuccess.header', 'Connect Seller Central Account')

  return (
    <>
      <AccountSyncSuccessWrapper>
        <HeaderWrapper>
          <P>{header}</P>
        </HeaderWrapper>
        <img src={iconLink} alt="sync success" />
        <Title>{t('mwsSync:AccountSyncSuccess.title', 'Success!')}</Title>
        <Subtitle>{message}</Subtitle>
      </AccountSyncSuccessWrapper>
      {errors ? renderErrors(errors) : null}
    </>
  )
}

AccountSyncSuccess.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  type: PropTypes.oneOf(['ppc', 'mws']).isRequired
}
