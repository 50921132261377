import PropTypes from 'prop-types'

export const breadcrumbTreeType = PropTypes.arrayOf(
  PropTypes.shape({
    position: PropTypes.number,
    name: PropTypes.string,
    nodeId: PropTypes.string
  })
)

export const categoryTreeType = PropTypes.arrayOf(
  PropTypes.shape({
    catId: PropTypes.number,
    name: PropTypes.string
  })
)

export const dimensionValuesDisplayDataType = PropTypes.arrayOf(
  PropTypes.shape({
    asin: PropTypes.string,
    differences: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
    lastSeen: PropTypes.number
  })
)

export const feeBreakdownType = PropTypes.shape({
  fbaFee: PropTypes.number,
  referralFee: PropTypes.number,
  variableClosingFee: PropTypes.number,
  totalFees: PropTypes.number
})

export const subCategoriesType = PropTypes.arrayOf(
  PropTypes.shape({
    rank: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
    id: PropTypes.string
  })
)

export const variationsType = PropTypes.arrayOf(
  PropTypes.shape({
    asin: PropTypes.string,
    attributes: PropTypes.arrayOf(
      PropTypes.shape({ dimension: PropTypes.string, value: PropTypes.string })
    )
  })
)

export const productType = PropTypes.shape({
  altScrapedAt: PropTypes.number,
  amazonChoiceKeyword: PropTypes.string,
  asin: PropTypes.string,
  brand: PropTypes.string,
  breadcrumbTree: breadcrumbTreeType,
  breadcrumbs: PropTypes.string,
  bsr_product: PropTypes.bool,
  bulletScore: PropTypes.number,
  calculatedCategory: PropTypes.string,
  category: PropTypes.string,
  categoryCode: PropTypes.string,
  categoryCodeFromRank: PropTypes.string,
  categoryNullifiedAt: PropTypes.number,
  categoryTree: categoryTreeType,
  collapsableParentAsin: PropTypes.string,
  country: PropTypes.string,
  createdAt: PropTypes.number,
  currency_code: PropTypes.string,
  descriptionScore: PropTypes.number,
  dimensionScrapedAt: PropTypes.number,
  dimensionUnit: PropTypes.string,
  dimensionValuesDisplayData: dimensionValuesDisplayDataType,
  dimensions: PropTypes.arrayOf(PropTypes.string),
  estRevenue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  estimatedSales: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  featureBullets: PropTypes.arrayOf(PropTypes.string),
  feeBreakdown: feeBreakdownType,
  fees: PropTypes.number,
  hasFetchableInventory: PropTypes.bool,
  hasVariants: PropTypes.bool,
  height: PropTypes.number,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  imageScore: PropTypes.number,
  imageUrl: PropTypes.string,
  initialScrapedAt: PropTypes.number,
  isAmazonChoice: PropTypes.bool,
  isBundle: PropTypes.bool,
  isComplete: PropTypes.bool,
  isSNS: PropTypes.bool,
  isSharedBSR: PropTypes.bool,
  isTracked: PropTypes.bool,
  isUnavailable: PropTypes.bool,
  length: PropTypes.number,
  listedAt: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  listedSince: PropTypes.string,
  listingId: PropTypes.string,
  listingQualityScore: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  merchantId: PropTypes.string,
  multipleSellers: PropTypes.bool,
  nReviews: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  nSellers: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  nameScore: PropTypes.number,
  net: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  noParentCategory: PropTypes.bool,
  oldBrand: PropTypes.string,
  pageAsin: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  productOverviewFeature: PropTypes.objectOf(PropTypes.string),
  product_url: PropTypes.string,
  rank: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rankSource: PropTypes.string,
  rating: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rawCategory: PropTypes.string,
  rootCategory: PropTypes.number,
  savingsAmount: PropTypes.number,
  savingsPercentage: PropTypes.number,
  scrapedAt: PropTypes.string,
  scrapedParentAsin: PropTypes.string,
  sellerName: PropTypes.string,
  sellerType: PropTypes.string,
  sessionId: PropTypes.string,
  state: PropTypes.string,
  subCategories: subCategoriesType,
  tier: PropTypes.string,
  variantAsins: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  variantAsinsCount: PropTypes.number,
  variantsCheckedAt: PropTypes.number,
  variations: variationsType,
  weight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  weightUnit: PropTypes.string,
  width: PropTypes.number
})

export const sellerProductType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  amazon_seller_account_id: PropTypes.number,
  asin: PropTypes.string,
  sku: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  amazon_image_url: PropTypes.string,
  reorder_days_of_supply: PropTypes.number,
  lead_time_in_days: PropTypes.number,
  last_synced_at: PropTypes.string,
  sync_status: PropTypes.number,
  review_request_enabled: PropTypes.bool,
  review_request_delay: PropTypes.number,
  parent_asin_grouping: PropTypes.string,
  enabled_toggle_value: PropTypes.number,
  parent_is_child: PropTypes.bool,
  total_children: PropTypes.number,
  enabled_toggle: PropTypes.string,
  total_resync_num: PropTypes.number,
  used_resync_num: PropTypes.number
})
