import {
  LAUNCH_BASE_URLS,
  SALES_ANALYTICS_URL,
  SALES_ANALYTICS_URLS,
  INVENTORY_URLS,
  LISTING_BUILDER_BASE,
  SHARE_OF_VOICE_URL,
  SALES_ESTIMATOR_URL,
  REVIEW_ANALYSIS_BASE,
  PRODUCT_RESEARCH_URLS,
  RANK_TRACKER_V2_BASE,
  SUPPLIER_URLS,
  KEYWORD_LISTS_URL,
  KEYWORD_SCOUT_URL,
  ACADEMY_URL,
  EXTENSION_URL,
  ADMIN_URL,
  DASHBOARD_URL,
  PAGE_NAMES,
  ADVERTISING_URLS,
  PRODUCT_COSTS_AND_SETTINGS_URL,
  INVENTORY_REIMBURSEMENTS_URL,
  DEVELOPER_URL,
  COMPETITIVE_INTELLIGENCE_URL
} from 'constants/routes'
import { Features } from 'constants/feature_names'
import { LIMIT_KEYS } from 'constants/feature_limits'
import { PERMISSION_KEYS } from 'constants/account/permissions'
import { NAVIGATION_DATA_ATTRIBUTE_IDS } from 'constants/data_attributes/navigation'

import { isBeforeToday } from 'helpers/date'

import { isFeatureEnabled } from './features'
import { hasFeatureLimit } from './feature_limits'

export const buildMenuItems = ({ t, flagData, featureLimits }) => {
  function renderBadge(end, text) {
    return isBeforeToday(end) ? null : text
  }

  const isNewShareOfVoice =
    isFeatureEnabled(Features.KEYWORDS_SHARE_OF_VOICE, flagData) &&
    featureLimits?.allowedMarketplaces?.length
      ? renderBadge('2024-02-17', t('generic:BetaTag', 'BETA'))
      : undefined

  const pageNames = PAGE_NAMES(t)

  return [
    {
      name: pageNames.dashboard,
      dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.HomeDashboard,
      iconName: 'NAV_HOME',
      url: DASHBOARD_URL
    },
    {
      isNew: renderBadge('2024-10-22', t('generic:NewTag', 'NEW')),
      name: pageNames.competitiveIntelligence,
      iconName: 'NAV_CI',
      url: COMPETITIVE_INTELLIGENCE_URL,
      permissionKey: [PERMISSION_KEYS.COMPETITIVE_INTELLIGENCE],
      isCurrentPath: currentPath =>
        !!currentPath.startsWith(COMPETITIVE_INTELLIGENCE_URL)
    },
    {
      name: pageNames.finder,
      dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.ProductResearch,
      iconName: 'NAV_PROD_RESEARCH',
      url: PRODUCT_RESEARCH_URLS.database,
      permissionKey: [PERMISSION_KEYS.FIND_PRODUCTS, PERMISSION_KEYS.TOOLBOX],
      children: [
        {
          name: pageNames.db,
          dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.ProductDatabase,
          url: PRODUCT_RESEARCH_URLS.database,
          permissionKey: [PERMISSION_KEYS.FIND_PRODUCTS]
        },
        {
          name: pageNames.opFinder,
          dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.OpportunityFinder,
          url: PRODUCT_RESEARCH_URLS.opportunityFinder,
          permissionKey: [PERMISSION_KEYS.FIND_PRODUCTS]
        },
        {
          name: pageNames.tracker,
          dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.ProductTracker,
          url: PRODUCT_RESEARCH_URLS.tracker,
          permissionKey: [PERMISSION_KEYS.FIND_PRODUCTS]
        },
        {
          name: pageNames.categoryTrends,
          dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.CategoryTrends,
          url: PRODUCT_RESEARCH_URLS.categoryTrends
        },
        {
          name: pageNames.salesEstimator,
          dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.SalesEstimator,
          url: SALES_ESTIMATOR_URL,
          permissionKey: [PERMISSION_KEYS.TOOLBOX]
        },
        ...(hasFeatureLimit(featureLimits, LIMIT_KEYS.aiFeatures)
          ? [
              {
                name: pageNames.listingAnalyzer,
                dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.ReviewAnalysis,
                url: REVIEW_ANALYSIS_BASE,
                permissionKey: [PERMISSION_KEYS.TOOLBOX]
              }
            ]
          : [])
      ]
    },
    {
      name: pageNames.supplier,
      dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.Suppliers,
      iconName: 'NAV_SUPPLIERS',
      url: SUPPLIER_URLS.supplier,
      permissionKey: [PERMISSION_KEYS.SUPPLIERS],
      children: [
        {
          name: pageNames.supplierDb,
          dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.SupplierDatabase,
          url: SUPPLIER_URLS.supplier,
          children: [
            { url: SUPPLIER_URLS.supplier },
            { url: SUPPLIER_URLS.results },
            { url: SUPPLIER_URLS.view }
          ],
          permissionKey: [PERMISSION_KEYS.SUPPLIERS]
        },
        {
          name: pageNames.supplierTracker,
          dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.SupplierTracker,
          url: SUPPLIER_URLS.tracker,
          permissionKey: [PERMISSION_KEYS.SUPPLIERS]
        }
      ]
    },
    {
      name: pageNames.keyword,
      dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.Keywords,
      iconName: 'NAV_KEYWORDS',
      url: KEYWORD_SCOUT_URL,
      permissionKey: [PERMISSION_KEYS.KEYWORDS],
      children: [
        {
          name: pageNames.keywordScout,
          dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.KeywordScout,
          url: KEYWORD_SCOUT_URL,
          permissionKey: [PERMISSION_KEYS.KEYWORDS]
        },
        {
          name: pageNames.keywordList,
          dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.KeywordLists,
          url: KEYWORD_LISTS_URL,
          permissionKey: [PERMISSION_KEYS.KEYWORDS],
          isCurrentPath: currentPath =>
            !!currentPath.startsWith(KEYWORD_LISTS_URL)
        },
        {
          name: pageNames.rankTracker,
          dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.RankTracker,
          url: RANK_TRACKER_V2_BASE,
          permissionKey: [PERMISSION_KEYS.KEYWORDS],
          isCurrentPath: currentPath =>
            !!currentPath.startsWith(RANK_TRACKER_V2_BASE)
        },
        // Share of Voice won't work if the user doesn't have allowed marketplaces, even if they have the permission & flag
        ...(featureLimits?.allowedMarketplaces?.length
          ? [
              {
                isNew: isNewShareOfVoice,
                name: pageNames.shareOfVoice,
                dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.ShareOfVoice,
                features: [Features.KEYWORDS_SHARE_OF_VOICE],
                url: SHARE_OF_VOICE_URL,
                permissionKey: [PERMISSION_KEYS.SHARE_OF_VOICE]
              }
            ]
          : [])
      ]
    },
    {
      name: pageNames.reviewAutomation,
      dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.ReviewAutomation,
      iconName: 'NAV_REVIEW_AUTOMATION',
      url: LAUNCH_BASE_URLS.reviewAutomation,
      permissionKey: [PERMISSION_KEYS.LAUNCH]
    },
    {
      name: pageNames.analytics,
      dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.SalesAnalytics,
      iconName: 'NAV_ANALYTICS',
      url: SALES_ANALYTICS_URL,
      permissionKey: [
        PERMISSION_KEYS.SALES_ANALYTICS,
        PERMISSION_KEYS.ADVERTISING
      ],
      children: [
        {
          name: pageNames.profit,
          dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.ProfitOverview,
          url: SALES_ANALYTICS_URLS.profitOverview,
          permissionKey: [PERMISSION_KEYS.SALES_ANALYTICS]
        },
        {
          name: pageNames.profitAndLoss,
          dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.PAndLStatement,
          url: SALES_ANALYTICS_URLS.profitAndLoss,
          permissionKey: [PERMISSION_KEYS.SALES_ANALYTICS]
        },
        {
          name: pageNames.expenses,
          dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.OtherTransactions,
          url: SALES_ANALYTICS_URLS.expenses,
          permissionKey: [PERMISSION_KEYS.SALES_ANALYTICS]
        },
        {
          dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.AdsAnalytics,
          name: pageNames.adsAnalytics,
          permissionKey: [PERMISSION_KEYS.ADVERTISING],
          url: ADVERTISING_URLS.analytics
        }
      ]
    },
    {
      name: pageNames.manageProd,
      dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.ManageProducts,
      iconName: 'NAV_MANAGE_PROD',
      url: PRODUCT_COSTS_AND_SETTINGS_URL,
      permissionKey: [PERMISSION_KEYS.MY_PRODUCTS, PERMISSION_KEYS.KEYWORDS],
      children: [
        {
          name: pageNames.listingBuilder,
          dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.ListingBuilder,
          url: LISTING_BUILDER_BASE,
          permissionKey: [PERMISSION_KEYS.KEYWORDS],
          isCurrentPath: currentPath =>
            !!currentPath.startsWith(LISTING_BUILDER_BASE)
        },
        {
          name: pageNames.fbaReimbursements,
          dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.FBAReimbursements,
          url: INVENTORY_REIMBURSEMENTS_URL,
          permissionKey: [PERMISSION_KEYS.FBA_REIMBURSEMENT]
        },
        {
          name: pageNames.inventory,
          dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.InventoryManager,
          url: INVENTORY_URLS.inventoryForecast,
          permissionKey: [PERMISSION_KEYS.MY_PRODUCTS]
        },
        {
          name: pageNames.costsAndSettings,
          url: PRODUCT_COSTS_AND_SETTINGS_URL,
          permissionKey: [PERMISSION_KEYS.MY_PRODUCTS]
        }
      ]
    },
    {
      name: pageNames.extension,
      dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.TheExtension,
      iconName: 'NAV_EXTENSION',
      url: EXTENSION_URL,
      secondary: true
    },
    {
      isNew: renderBadge('2024-07-01', t('generic:NewTag', 'NEW')),
      name: pageNames.developer,
      url: DEVELOPER_URL,
      iconName: 'NAV_DEVELOPER',
      permissionKey: [PERMISSION_KEYS.PUBLIC_API],
      secondary: true
    },
    {
      name: pageNames.academy,
      dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.Academy,
      iconName: 'NAV_ACADEMY',
      url: ACADEMY_URL,
      permissionKey: [PERMISSION_KEYS.ACADEMY],
      isCurrentPath: currentPath => !!currentPath.startsWith(ACADEMY_URL),
      secondary: true
    },
    {
      name: pageNames.admin,
      dataId: NAVIGATION_DATA_ATTRIBUTE_IDS.AdminPanel,
      iconName: 'NAV_ADMIN_PANEL',
      url: ADMIN_URL,
      isCurrentPath: currentPath => !!currentPath.startsWith(ADMIN_URL),
      secondary: true
    }
  ]
}
