import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Button, Icon, Modal } from '@junglescout/edna'

import { setGlobalModal } from 'actions/index'

import { UPGRADE_LIMIT_MODAL } from 'constants/feature_limits'

import { sendCtaClickEvent } from 'helpers/segment'
import { getPricingPageUrl } from 'helpers/account'
import { formatCurrency } from 'helpers/currency_formatting'
import { billingFrequencyIntervalFormatter } from 'helpers/billing'

import { useUpgradeFeatureLimitInfo } from 'hooks/upgrade_limit_modal'

import HoverGraphic from 'icons/svg/hover.svg'

import { ComparisonFeatures } from './ComparisonFeatures'
import { CurrentPlanFeatures } from './CurrentPlanFeatures'
import { NewPlanFeatures } from './NewPlanFeatures'

const CloseButton = styled(Icon)`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;

  color: ${props => props.theme.colors.grey600};
`
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
const UsageCounter = styled.div`
  position: relative;

  width: 45px;
  text-align: center;

  color: ${props => props.theme.colors.grey600};

  font-weight: 600;
  font-size: 12px;
  line-height: 24px;

  margin: auto;
`
const UsageCounterGraphic = styled.img`
  position: absolute;
  z-index: -1;
  margin-top: -1px;

  top: 0;
  left: 0;
`
const UsageBar = styled.div`
  width: 556px;
  height: 8px;

  margin: 15px auto auto auto;

  // This is so the border doesn't affect the height
  box-sizing: content-box;

  background: linear-gradient(
    90deg,
    ${props => props.theme.colors.orange500} 4.07%,
    #ffb800 101.05%
  );

  border: 2px solid ${props => props.theme.colors.grey100};
  border-radius: 31px;
`
const HeaderContainer = styled.div`
  padding: 20px 0;

  display: flex;
  flex-direction: column;
  gap: 4px;

  border-bottom: 1px solid ${props => props.theme.colors.grey100};
`
const Title = styled.h1`
  margin: 0;
  color: ${props => props.theme.colors.grey900};
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
`
const Description = styled.h1`
  color: ${props => props.theme.colors.grey600};
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
`
const UpgradeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 8px;
  gap: 6px;
`
const UpgradeComparisonColumnIcon = styled(Icon)`
  color: ${props => props.theme.colors.orange500};
  height: 20px;
`
const UpgradeComparisonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const UpgradeComparisonColumnGroup = styled.div`
  display: flex;
  flex-direction: row;

  gap: 18px;
`
const UpgradeComparisonColumn = styled.div`
  color: ${props => props.theme.colors.grey900};
  display: flex;
  flex-direction: column;

  gap: 8px;

  font-weight: 400;
  font-size: 12px;
  line-height: 20px;

  &.center {
    text-align: center;
  }

  &.old {
    color: ${props => props.theme.colors.grey500};
  }

  &.new {
    color: ${props => props.theme.colors.grey900};
  }

  &.new > div:first-child {
    color: ${props => props.theme.colors.orange500};
  }

  & > div:first-child {
    font-size: 12px;
    line-height: 20px;

    text-transform: uppercase;
    font-weight: 500;
  }

  &.primary > div:first-child {
    font-size: 15px;
    text-transform: none;
    font-weight: 600;
  }
`
const UpgradePlanContainer = styled.div`
  background: ${props => props.theme.colors.orange100};
  border-radius: 4px;
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
`
const UpgradePriceContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
`
const UpgradePrice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  color: ${props => props.theme.colors.grey600};

  & > div:first-child {
    text-transform: uppercase;

    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
  }

  & > .price {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
  }

  & > .price > .interval {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }

  &.new {
    color: ${props => props.theme.colors.grey900};
  }
`
const LearnMore = styled.div`
  text-align: center;
  color: ${props => props.theme.colors.grey600};

  font-weight: 400;
  font-size: 12px;
  line-height: 20px;

  a {
    color: ${props => props.theme.colors.orange500};
    text-decoration: underline;
  }
`
const UpgradePriceIcon = styled(Icon)`
  color: ${props => props.theme.colors.orange500};
`
const NoThanks = styled.div`
  padding: 5px 10px;
  margin: auto;

  font-weight: 500;
  font-size: 11px;
  line-height: 16px;

  color: ${props => props.theme.colors.grey900};
  cursor: pointer;
`

const getToolName = initiator => {
  switch (initiator) {
    case 'Product Database':
      return ['Product Database', 'productDatabase']
    case 'Opportunity Finder':
      return ['Opportunity Finder', 'opportunityFinder']
    case 'Product Tracker':
      return ['Product Tracker', 'productTracker']
    case 'Sales Estimator':
      return ['Sales Estimator', 'salesEstimator']
    case 'Keyword Scout':
      return ['Keyword Scout', 'keywordScout']
    case 'Supplier Database':
      return ['Supplier Database', 'supplierDatabase']
    case 'Supplier Tracker':
      return ['Supplier Tracker', 'supplierTracker']
    case 'Rank Tracker':
      return ['Rank Tracker', 'rankTracker']
    case 'Listing Builder':
      return ['Listing Builder', 'listingBuilder']
    case 'AI Features':
      return ['AI Features', 'aiFeatures']
    case 'AI Chatbot ':
      return ['AI Features', 'aiFeaturesChat']
    case 'AI Chatbot Requests':
      return ['AI Features', 'aiFeaturesChatRequests']
    default:
      return []
  }
}

export const UpgradeSearchLimitModal = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const {
    modal,
    featureLimits,
    membershipInfo,
    membershipInfo: { group },
    user: {
      checkout_locale: checkoutLocale,
      selected_language: selectedLanguage
    }
  } = useSelector(state => state.globalData)
  const modalMetadata = useSelector(state => state.globalData.modalMetadata)

  const initiator = modalMetadata?.initiator || 'default'
  const [toolName, featureLimitName] = getToolName(initiator)
  const limit = featureLimits[featureLimitName]
  const isSearchLimit =
    limit?.featureUsageCount !== undefined && limit?.featureUsageCount !== null

  const {
    upgradePlanDetails,
    upgradeCheckoutDetails: {
      url,
      price,
      currency,
      billingFrequency,
      chargeFrequency
    }
  } = useUpgradeFeatureLimitInfo()

  const pricingUrl = getPricingPageUrl(
    checkoutLocale,
    currency,
    selectedLanguage,
    group
  )

  const defaultDescription = () => {
    return (
      <Description>
        {t(
          'upgradeModal.description.text',
          'Your next {{featureLimit}} {{toolName}} searches will be available at {{time}}. To unlock unlimited searches, upgrade now.',
          {
            toolName,
            featureLimit: limit.featureLimit,
            time:
              limit.intervalTimeframe === 'month'
                ? t('upgradeModal.description.monthly', 'the end of the month')
                : t('upgradeModal.description.daily', '12 a.m. UTC')
          }
        )}
      </Description>
    )
  }

  const genericTrackerDescription = () => {
    return (
      <Description>
        {t(
          'upgradeModal.description.trackerText',
          'You have used all your available {{trackerName}} tracks. To unlock more {{trackerName}} tracks, upgrade now.',
          { trackerName: initiator }
        )}
      </Description>
    )
  }

  const nonDynamicDescriptionsObject = {
    'AI Features': t(
      'upgradeModal.description.aiFeaturesText',
      'To unlock advanced AI Assist features, upgrade your Plan.'
    ),
    'Rank Tracker': t(
      'upgradeModal.description.rankTrackerText',
      'You have used all your available Rank Tracker keywords. To unlock more Rank Tracker keywords, upgrade now.'
    ),
    'Listing Builder': t(
      'upgradeModal.description.listingBuilderText',
      'You have used all your available listings. To unlock more listings, upgrade now.'
    )
  }

  const nonDynamicDescriptions = () => {
    return <Description>{nonDynamicDescriptionsObject[toolName]}</Description>
  }

  const renderTitle = () => {
    switch (toolName) {
      case 'AI Features':
        return t(
          'upgradeModal.description.aiFeaturesTitle',
          'Upgrade for more AI Assist'
        )
      default:
        return t('upgradeModal.title', "You're ready for more.")
    }
  }

  const renderDescription = () => {
    switch (toolName) {
      case 'Product Tracker':
      case 'Supplier Tracker':
        return genericTrackerDescription()
      case 'Rank Tracker':
      case 'Listing Builder':
      case 'AI Features':
        return nonDynamicDescriptions()
      default:
        return defaultDescription()
    }
  }

  const handleUpgrade = url => {
    sendCtaClickEvent({
      url,
      text: 'Upgrade Now',
      location: 'Upsell Modal',
      extraProps: { testVersion: 'SMB-TEST-4' }
    })

    window.open(url, '_blank', 'noopener noreferrer')
  }

  const isOpen = modal === UPGRADE_LIMIT_MODAL

  if (isOpen && !(toolName || limit))
    throw new Error('Unsupported tool for upgrade limit modal')

  return (
    <Modal
      open={isOpen}
      onOpenChange={() => {
        dispatch(setGlobalModal())

        sendCtaClickEvent({
          destination: window.location.href,
          text: 'Modal Closed',
          location: 'Upsell Modal',
          extraProps: { testVersion: 'SMB-TEST-4' }
        })
      }}>
      <Modal.Content width="608px" maxWidth="608px" ariaTitle="Upgrade">
        <ContentContainer>
          <Modal.Close>
            <span>
              <CloseButton name="X_CLOSE" />
            </span>
          </Modal.Close>
          <HeaderContainer>
            {isSearchLimit && (
              <>
                <UsageBar />
                <UsageCounter>
                  {Math.min(limit.featureLimit, limit.featureUsageCount)}/
                  {limit.featureLimit}
                  <UsageCounterGraphic src={HoverGraphic} />
                </UsageCounter>
              </>
            )}
            <Title>{renderTitle()}</Title>
            {limit && renderDescription()}
          </HeaderContainer>
          <UpgradeContainer>
            <UpgradeComparisonContainer>
              <UpgradeComparisonColumn className="primary">
                <ComparisonFeatures />
              </UpgradeComparisonColumn>
              <UpgradeComparisonColumnGroup>
                <UpgradeComparisonColumn className="center old">
                  <CurrentPlanFeatures />
                </UpgradeComparisonColumn>
                <UpgradeComparisonColumn className="center">
                  <div>&nbsp;</div>
                  {Array.from({ length: 10 }, (_, index) => (
                    <UpgradeComparisonColumnIcon
                      key={index}
                      name="ARROW_RIGHT"
                      width={10}
                    />
                  ))}
                </UpgradeComparisonColumn>
                <UpgradeComparisonColumn className="center new">
                  <NewPlanFeatures />
                </UpgradeComparisonColumn>
              </UpgradeComparisonColumnGroup>
            </UpgradeComparisonContainer>
          </UpgradeContainer>
          <LearnMore>
            <Trans i18nKey="upgradeModal.learnMore">
              Learn more about all our plans by viewing our{' '}
              <a href={pricingUrl} target="_blank" rel="noopener noreferrer">
                Pricing Page
              </a>
            </Trans>
          </LearnMore>
          <UpgradePlanContainer>
            <UpgradePriceContainer>
              <UpgradePrice>
                <div>{t('upgradeModal.yourPlan.title', 'Your Plan')}</div>
                <div className="price">
                  {formatCurrency(
                    membershipInfo?.price / 100.0,
                    membershipInfo?.stripe_plan?.currency
                  )}
                  <span className="interval">
                    {`/ ${billingFrequencyIntervalFormatter(
                      membershipInfo?.billing_frequency,
                      membershipInfo?.charge_frequency
                    )}`}
                  </span>
                </div>
              </UpgradePrice>
              <UpgradePriceIcon name="ARROW_RIGHT" width={18} />
              <UpgradePrice className="new">
                <div>{upgradePlanDetails.name}</div>
                <div className="price">
                  {formatCurrency(price, currency)}
                  <span className="interval">
                    {`/ ${billingFrequencyIntervalFormatter(
                      billingFrequency,
                      chargeFrequency
                    )}`}
                  </span>
                </div>
              </UpgradePrice>
            </UpgradePriceContainer>
            <Button onClick={() => handleUpgrade(url)}>
              {t('upgradeModal.upgrade', 'Upgrade Now')}
            </Button>
          </UpgradePlanContainer>
          <Modal.Close>
            <NoThanks role="button">
              {t('upgradeModal.noThanks', 'No Thanks')}
            </NoThanks>
          </Modal.Close>
        </ContentContainer>
      </Modal.Content>
    </Modal>
  )
}
