// range filters still need type since it's being used on client side
export const DEFAULT_DATABASE_FILTERS = {
  listedAt: {
    min: undefined,
    max: undefined,
    primaryField: 'listedAt',
    secondaryField: 'estimatedListedAt'
  },
  includeKeywords: {
    searchTerm: '',
    queryFields: ['name', 'brand', 'asin']
  },
  excludeKeywords: {
    searchTerm: '',
    queryFields: ['name', 'brand', 'asin']
  },
  calculatedCategory: {
    valuesArray: []
  },
  country: {
    valuesArray: ['us']
  },
  sort: {
    column: 'name',
    direction: 'asc'
  },
  paginate: {
    pageSize: 50,
    from: 0
  },
  tier: {
    valuesArray: []
  },
  sellerType: {
    valuesArray: []
  },
  isUnavailable: {
    valuesArray: [false]
  },
  price: {
    type: 'range',
    min: null,
    max: null
  },
  net: {
    type: 'range',
    min: null,
    max: null
  },
  rank: {
    type: 'range',
    min: null,
    max: null
  },
  estimatedSales: {
    type: 'range',
    min: null,
    max: null
  },
  estRevenue: {
    type: 'range',
    min: null,
    max: null
  },
  nReviews: {
    type: 'range',
    min: null,
    max: null
  },
  rating: {
    type: 'range',
    min: null,
    max: null
  },
  weight: {
    type: 'range',
    min: null,
    max: null
  },
  nSellers: {
    type: 'range',
    min: null,
    max: null
  },
  listingQualityScore: {
    type: 'range',
    min: 1,
    max: 100
  },
  isComplete: {
    valuesArray: [true]
  },
  state: {
    valuesArray: ['active']
  }
}

export const DEFAULT_OPPORTUNITY_FINDER_FILTERS = {
  query: {
    type: 'query',
    searchTerm: '',
    queryFields: ['name']
  },
  eQuery: {
    type: 'eQuery',
    searchTerm: '',
    queryFields: ['name']
  },
  category: {
    type: 'terms',
    valuesArray: []
  },
  country: {
    type: 'terms',
    valuesArray: ['us']
  },
  sort: {
    type: 'sort',
    column: 'opportunityScore',
    direction: 'desc'
  },
  paginate: {
    type: 'paginate',
    pageSize: 100,
    from: 0
  },
  avgUnitsSold: {
    type: 'range',
    min: 0,
    max: 999999
  },
  estimatedExactSearchVolume: {
    type: 'range',
    min: 151,
    max: 999999999
  },
  competition: {
    type: 'range',
    min: 0,
    max: 9999
  },
  avgPrice: {
    type: 'range',
    min: 0,
    max: 9999999
  },
  opportunityScore: {
    type: 'range',
    min: 1,
    max: 9999
  },
  source: {
    type: 'terms',
    valuesArray: ['ba']
  },
  resultCount: {
    // this will remove any ASINs that show in the search results.
    type: 'range',
    min: 5,
    max: 999999
  },
  isComplete: {
    type: 'terms',
    valuesArray: [true]
  },
  classification5: {
    type: 'range',
    min: 1,
    max: 9999
  },
  monthlyTrend: {
    type: 'range',
    min: undefined,
    max: undefined
  },
  quarterlyTrend: {
    type: 'range',
    min: undefined,
    max: undefined
  }
}

export const DEFAULT_KEYWORD_ENGINE_FILTERS = {
  rawAsins: {
    type: 'terms',
    valuesArray: []
  },
  shingles: {
    type: 'shingles',
    column: 'name',
    searchTerm: ''
  },
  category: {
    type: 'terms',
    valuesArray: []
  },
  country: {
    type: 'terms',
    valuesArray: ['us']
  },
  wordCount: {
    type: 'range',
    min: '',
    max: ''
  },
  isComplete: {
    type: 'terms',
    valuesArray: [true]
  },
  state: {
    type: 'terms',
    valuesArray: ['active']
  },
  estimatedExactSearchVolume: {
    type: 'range',
    min: '',
    max: ''
  },
  estimatedBroadSearchVolume: {
    type: 'range',
    min: '',
    max: ''
  },
  organicProductCount: {
    type: 'range',
    min: '',
    max: ''
  },
  resultCount: {
    type: 'range',
    min: '',
    max: ''
  },
  organicRank: {
    type: 'range',
    min: '',
    max: ''
  },
  organicRankingAsins: {
    type: 'range',
    min: '',
    max: ''
  },
  overallRank: {
    type: 'range',
    min: '',
    max: ''
  },
  avgCompetitorOrganicRank: {
    type: 'range',
    min: '',
    max: ''
  },
  relativeOrganicPosition: {
    type: 'range',
    min: '',
    max: ''
  },
  sponsoredProductCount: {
    type: 'range',
    min: '',
    max: ''
  },
  sponsoredRank: {
    type: 'range',
    min: '',
    max: ''
  },
  top3ClickShare: {
    type: 'range',
    min: '',
    max: ''
  },
  top3ConversionShare: {
    type: 'range',
    min: '',
    max: ''
  },
  sponsoredRankingAsins: {
    type: 'range',
    min: '',
    max: ''
  },
  relativeSponsoredPosition: {
    type: 'range',
    min: '',
    max: ''
  },
  avgCompetitorSponsoredRank: {
    type: 'range',
    min: '',
    max: ''
  },
  exactSuggestedBidMedian: {
    type: 'range',
    min: '',
    max: ''
  },
  broadSuggestedBidMedian: {
    type: 'range',
    min: '',
    max: ''
  },
  includeKeywords: {
    type: 'term',
    searchTerm: ''
  },
  query: {
    type: 'term',
    searchTerm: ''
  },
  excludeKeywords: {
    type: 'term',
    searchTerm: ''
  },
  eQuery: {
    type: 'term',
    searchTerm: ''
  },
  easeOfRankingScore: {
    type: 'range',
    min: '',
    max: ''
  },
  sort: {
    type: 'sort',
    column: 'estimatedExactSearchVolume',
    direction: 'desc'
  },
  paginate: {
    type: 'paginate',
    pageSize: 250,
    from: 0
  }
}

export const STANDARD_TIERS = [
  'Standard (Small)',
  'Standard (Large)',
  'Standard (Small Letter)',
  'Standard (Large Letter)',
  'Standard (Small Envelope)',
  'Standard (Standard Envelope)',
  'Standard (Large Envelope)',
  'Standard (Standard Parcel)',
  'Small',
  'Standard',
  'Envelope'
]

export const OVERSIZE_TIERS = [
  'Oversize (Small)',
  'Oversize (Medium)',
  'Oversize (Standard)',
  'Oversize (Large)',
  'Special',
  'Special Oversize',
  'Oversize'
]

export const KS_FILTER_LABEL = {
  filters: t => t('generic:Filters', 'Filters'),
  noSelected: t => t('generic:no_selected', 'None Selected'),
  numSelected: (t, count) =>
    t('generic:num_selected', '{{count}} Selected', { count }),
  category: t => t('generic:Categories', 'Categories'),
  basicFilters: {
    estimatedExactSearchVolume: t =>
      t(
        'keyword_engine:FilterLabels.estimatedExactSearchVolume',
        'Exact Match (30 Days)'
      ),
    estimatedBroadSearchVolume: t =>
      t(
        'keyword_engine:FilterLabels.estimatedBroadSearchVolume',
        'Broad Match (30 Days)'
      ),
    wordCount: t => t('keyword_engine:FilterLabels.wordCount', 'Word Count'),
    organicProductCount: t =>
      t(
        'keyword_engine:FilterLabels.organicProductCount',
        'Organic Product Count'
      ),
    sponsoredProductCount: t =>
      t(
        'keyword_engine:FilterLabels.AdvancedFilters.sponsoredProductCount',
        'Sponsored Product Count'
      )
  },
  // advanced filters
  rankingFilters: {
    organicRank: t =>
      t(
        'keyword_engine:FilterLabels.AdvancedFilters.organicRank',
        'Organic Rank'
      ),
    sponsoredRank: t =>
      t(
        'keyword_engine:FilterLabels.AdvancedFilters.sponsoredRank',
        'Sponsored Rank'
      ),
    overallRank: t =>
      t(
        'keyword_engine:FilterLabels.AdvancedFilters.overallRank',
        'Overall Rank'
      )
  },
  competingProductsFilters: {
    avgCompetitorOrganicRank: t =>
      t(
        'keyword_engine:FilterLabels.AdvancedFilters.avgOrganicRank',
        'Avg. Organic Rank'
      ),
    avgCompetitorSponsoredRank: t =>
      t(
        'keyword_engine:FilterLabels.AdvancedFilters.avgSponsoredRank',
        'Avg. Sponsored Rank'
      ),
    organicRankingAsins: t =>
      t(
        'keyword_engine:FilterLabels.AdvancedFilters.organicAsins',
        'Organic ASINs (Count)'
      ),
    sponsoredRankingAsins: t =>
      t(
        'keyword_engine:FilterLabels.AdvancedFilters.sponsoredRankingAsins',
        'Sponsored ASINs (Count)'
      ),
    relativeOrganicPosition: t =>
      t(
        'keyword_engine:FilterLabels.AdvancedFilters.relativeOrganicPosition',
        'Relative Organic Position'
      ),
    relativeSponsoredPosition: t =>
      t(
        'keyword_engine:FilterLabels.AdvancedFilters.relativeSponsoredPosition',
        'Relative Sponsored Position'
      )
  }
}
