import React from 'react'
import moment from 'moment'
import orderBy from 'lodash/orderBy'

import { Tooltip } from '@junglescout/edna'

import { KEYWORD_ENGINE_TOOLTIPS } from 'constants/keyword_engine/tooltips'
import { KEYWORD_ENGINE_SEARCH_TYPES } from 'constants/keyword_engine_search_types'
import { DEFAULT_KEYWORD_ENGINE_FILTERS } from 'constants/filters'

import { parseCurrency } from 'helpers/currency'
import { getItemsSelected } from 'helpers/selectElement'
import { isAsin, everyIsAsin } from 'helpers/amazon'
import { isFeatureEnabled } from 'helpers/features'
import { removeNullAndEmpty } from 'helpers/objects'
import { averageByProperty } from 'helpers/arrays'
import { PRODUCT_FOR_SEGMENT, sendCtaClickEvent } from 'helpers/segment'
import { toNumeric } from 'helpers/formatters'
import {
  getPathData,
  safeSegmentCall,
  sendSegmentTrackEvent
} from 'services/segment'
import { createTrend } from './charts'

export const formatKeywords = (keywords, filters) => {
  if (filters.sort.column === 'score') {
    return orderBy(keywords, 'matches', filters.sort.direction)
  }

  return keywords
}

export const getKeywordScoutKeywordsSelected = (selectedKeywords, keyword) => {
  return getItemsSelected(selectedKeywords, keyword)
}

export const renderNaTooltip = column => {
  const tooltipContent = KEYWORD_ENGINE_TOOLTIPS.noData[column]

  if (tooltipContent) {
    return (
      <Tooltip content={tooltipContent} side="bottom" size="medium">
        <span>-</span>
      </Tooltip>
    )
  }
  return null
}

export const giveawayFormatter = val => {
  if (val) return parseInt(val, 10).toLocaleString()

  return renderNaTooltip('avgGiveaway')
}

export const bidFormatter = (val, item, column) => {
  if (val) return parseCurrency(val, item.currencyCode)

  return renderNaTooltip(column)
}

export const baseKeywordScoutFormatter = (val, column) => {
  if (val === 0) return val
  return val || renderNaTooltip(column)
}

export const bidFormatterV2 = (val, item, column) => {
  if (val) return parseCurrency(val, item.currencyCode)

  return renderNaTooltip(column)
}

export const parseCommaSeparatedTerms = line => {
  return line
    .split(',')
    .map(value => value.trim())
    .filter(value => value !== '')
}

export const getSearchType = searchTerm => {
  const { keyword, singleAsin, multipleAsins } = KEYWORD_ENGINE_SEARCH_TYPES

  // input is treated as keyword (text search, not ASIN(s))
  if (!Array.isArray(searchTerm)) return keyword
  if (searchTerm.length === 0) return keyword
  if (searchTerm.length === 1 && !isAsin(searchTerm[0])) return keyword

  // input is treated as single ASIN
  if (searchTerm.length === 1 && isAsin(searchTerm[0])) return singleAsin
  if (
    searchTerm.length > 1 &&
    isAsin(searchTerm[0]) &&
    !everyIsAsin(searchTerm)
  )
    return singleAsin

  // input is treated as multiple ASINs
  if (searchTerm.length > 1 && everyIsAsin(searchTerm)) return multipleAsins

  return keyword
}

export const filterColumnsBySearchType = props => {
  const { columns, flagData, currentSearchType } = props

  const enabledColumns = columns.filter(column => {
    // check conditions one by one
    if (
      column?.featureFlag &&
      !isFeatureEnabled(column.featureFlag, flagData)
    ) {
      return false
    }

    if (column?.displayFor && !column.displayFor.includes(currentSearchType)) {
      return false
    }

    return true
  })

  return enabledColumns
}

export const getTableKey = (id, searchType) => {
  switch (searchType) {
    case KEYWORD_ENGINE_SEARCH_TYPES.singleAsin:
      return `${id}-single`
    case KEYWORD_ENGINE_SEARCH_TYPES.multipleAsins:
      return `${id}-multiple`
    default:
      return `${id}-keyword`
  }
}

export const mapFiltersToApiParams = filters => {
  const {
    country,
    sort,
    paginate,
    rawAsins,
    shingles,
    category,
    wordCount,
    exactSuggestedBidMedian,
    broadSuggestedBidMedian,
    query,
    eQuery,
    easeOfRankingScore,
    estimatedExactSearchVolume,
    estimatedBroadSearchVolume,
    sponsoredProductCount,
    organicRank,
    overallRank,
    sponsoredRank,
    organicRankingAsins,
    sponsoredRankingAsins,
    avgCompetitorOrganicRank,
    avgCompetitorSponsoredRank,
    relativeOrganicPosition,
    relativeSponsoredPosition,
    top3ClickShare,
    top3ConversionShare,
    resultCount
  } = filters

  const mappedFilters = {
    country: country.valuesArray[0],
    column: sort.column,
    direction: sort.direction,
    page_size: paginate.pageSize,
    from: paginate.from,
    asins: rawAsins.valuesArray?.join(', '),
    search_terms: shingles.searchTerm,
    categories: category.valuesArray,
    word_count_min: Number(wordCount.min) || null,
    word_count_max: Number(wordCount.max) || null,
    exact_ppc_bid_min: Number(exactSuggestedBidMedian?.min) || null,
    exact_ppc_bid_max: Number(exactSuggestedBidMedian?.max) || null,
    broad_ppc_bid_min: Number(broadSuggestedBidMedian?.min) || null,
    broad_ppc_bid_max: Number(broadSuggestedBidMedian?.max) || null,
    include_keywords: query?.searchTerm,
    exclude_keywords: eQuery?.searchTerm,
    ease_of_ranking_min: Number(easeOfRankingScore?.min) || null,
    ease_of_ranking_max: Number(easeOfRankingScore?.max) || null,
    exact_match_min: Number(estimatedExactSearchVolume.min) || null,
    exact_match_max: Number(estimatedExactSearchVolume.max) || null,
    broad_match_min: Number(estimatedBroadSearchVolume.min) || null,
    broad_match_max: Number(estimatedBroadSearchVolume.max) || null,
    sponsored_product_min: Number(sponsoredProductCount.min) || null,
    sponsored_product_max: Number(sponsoredProductCount.max) || null,
    organic_product_min: Number(resultCount.min) || null,
    organic_product_max: Number(resultCount.max) || null,
    organic_rank_min: Number(organicRank.min) || null,
    organic_rank_max: Number(organicRank.max) || null,
    overall_rank_min: Number(overallRank.min) || null,
    overall_rank_max: Number(overallRank.max) || null,
    sponsored_rank_min: Number(sponsoredRank.min) || null,
    sponsored_rank_max: Number(sponsoredRank.max) || null,
    organic_ranking_asins_min: Number(organicRankingAsins.min) || null,
    organic_ranking_asins_max: Number(organicRankingAsins.max) || null,
    sponsored_ranking_asins_min: Number(sponsoredRankingAsins.min) || null,
    sponsored_ranking_asins_max: Number(sponsoredRankingAsins.max) || null,
    avg_competitor_organic_rank_min:
      Number(avgCompetitorOrganicRank.min) || null,
    avg_competitor_organic_rank_max:
      Number(avgCompetitorOrganicRank.max) || null,
    avg_competitor_sponsored_rank_min:
      Number(avgCompetitorSponsoredRank.min) || null,
    avg_competitor_sponsored_rank_max:
      Number(avgCompetitorSponsoredRank.max) || null,
    top_3_click_share_min: Number(top3ClickShare.min) || null,
    top_3_click_share_max: Number(top3ClickShare.max) || null,
    top_3_conversion_share_min: Number(top3ConversionShare.min) || null,
    top_3_conversion_share_max: Number(top3ConversionShare.max) || null,
    relative_organic_position_min: Number(relativeOrganicPosition.min) || null,
    relative_organic_position_max: Number(relativeOrganicPosition.max) || null,
    relative_sponsored_position_min:
      Number(relativeSponsoredPosition.min) || null,
    relative_sponsored_position_max:
      Number(relativeSponsoredPosition.max) || null
  }

  return removeNullAndEmpty(mappedFilters)
}

/**
 * Determine, either 'date' is the the closest match to 'rangeFirstEndDate' value and store it in 'currentClosestDate'
 * @param date - first date for the comparison
 * @param rangeFirstEndDate - second date for the comparison
 * @param currentClosestDate - result from the previous run of 'getClosestDate' method
 * @returns {*}
 */
function getClosestDate(date, rangeFirstEndDate, currentClosestDate) {
  let closestDate = currentClosestDate

  const diff = moment(date).diff(moment(rangeFirstEndDate), 'days')
  if (diff > 0) {
    if (closestDate) {
      if (moment(date).diff(moment(closestDate), 'days') < 0) {
        closestDate = date
      }
    } else {
      closestDate = date
    }
  }
  return closestDate
}

function formatWithMoment(date) {
  return date ? moment(date).format('MMM D, YYYY') : undefined
}

/**
 * Parse compared range data
 * @param data - full historical search volume data object
 * @param endDatesArr - array of 'estimateEndDate' values from 'data' object
 * @param closestComparedDate - first 'estimateEndDate' for the compared period
 * @param index - 'data' array index of the initial range's first week object, which is the week after compared range last week object
 * @param days - days of the range we get data for
 * @returns {{comparedRangeEndDate: *, comparedAvgSearchVolume: string, comparedRangeStartDate: *}|{}}
 */
function getComparedRangeData(
  data,
  endDatesArr,
  closestComparedDate,
  index,
  days
) {
  // Get data for comparison to the current range
  const comparedRangeData =
    data.slice(endDatesArr.indexOf(closestComparedDate), index) || []

  const comparedRangeStartDate = comparedRangeData[0]?.estimateStartDate
  const comparedRangeEndDate =
    comparedRangeData[comparedRangeData.length - 1]?.estimateEndDate

  // Get the number of days between the start and the end date for the compared period
  const comparedRangeLength = moment(comparedRangeEndDate).diff(
    moment(comparedRangeStartDate),
    'days'
  )
  // If even after adding 6 days its length less than range's number of days -> we do not have enough of data to compare
  const noComparedData = comparedRangeLength + 6 < days

  if (noComparedData) {
    return {}
  }

  const comparedAvgSearchVolume = averageByProperty(
    comparedRangeData,
    'estimatedExactSearchVolume'
  )

  return {
    comparedAvgSearchVolume: Math.round(comparedAvgSearchVolume),
    comparedRangeStartDate: formatWithMoment(comparedRangeStartDate),
    comparedRangeEndDate: formatWithMoment(comparedRangeEndDate)
  }
}

/**
 * Parse data for every range + for its compared period
 * @param data - historical search volume data
 * @param ranges - constant array of available ranges user can select
 * @returns {*[]}
 */
export function parseSearchVolumeData(data, ranges) {
  const dataLength = data?.length

  if (!dataLength || !ranges?.length) {
    return []
  }
  const insights = []

  // Create an array of 'estimateEndDate' values out of data array of objects
  const endDatesArr = data.map(item => item.estimateEndDate)
  // Take the last 'estimateEndDate' from 'data' object -> it is the last day we have data for
  const rangeLastEndDate = moment(endDatesArr[endDatesArr.length - 1])

  // Get data array and insights for every range (note: no insights for 2Y, as we won't have data to compare to)
  ranges?.map(({ days, value, showUpsell, rangeLabel }) => {
    const is2YRange = value === '2_YEARS'

    // From the last available end date, subtract the number of range days to get the first 'estimateEndDate' of the range
    const rangeFirstEndDate = rangeLastEndDate.clone().subtract(days, 'days')
    // Do the same for 'rangeFirstEndDate' to get the first 'estimateEndDate' of the compared range.
    // For ex. we compare search volume for this and previous months, or this and previous years, etc
    const comparedPrevPeriodFirstEndDate = is2YRange
      ? null
      : rangeFirstEndDate.clone().subtract(days, 'days')

    // Find the closest date to the 'rangeFirstEndDate' in the 'endDatesArr' to find the 'data' array index,
    // based on which we can slice 'data' array and get the historical search volume data for the selected periods
    let closestDate
    let closestComparedDate

    endDatesArr.forEach(date => {
      closestDate = getClosestDate(date, rangeFirstEndDate, closestDate)
      closestComparedDate = is2YRange
        ? null
        : getClosestDate(
            date,
            comparedPrevPeriodFirstEndDate,
            closestComparedDate
          )
    })

    // Get 'data' array index of the range's first week object
    const index = endDatesArr.indexOf(closestDate)

    // Get data for the current range
    const rangeData = data.slice(index) || []
    const comparedRangeData = getComparedRangeData(
      data,
      endDatesArr,
      closestComparedDate,
      index,
      days
    )

    const avgSearchVolume =
      averageByProperty(rangeData, 'estimatedExactSearchVolume') || 0

    insights.push({
      value,
      days,
      showUpsell,
      rangeLabel,
      data: rangeData,
      avgSearchVolume: Math.round(avgSearchVolume),
      rangeStartDate: formatWithMoment(rangeData[0]?.estimateStartDate),
      rangeEndDate: formatWithMoment(
        rangeData[rangeData.length - 1]?.estimateEndDate
      ),
      ...comparedRangeData
    })
  })

  return insights
}

export function trackSegmentRowClickEvent(e) {
  const targetClassname = e?.target?.className
  const elClassName =
    typeof targetClassname === 'string' && targetClassname.split('-')?.[0]

  // Avoid sending duplicate row click events if that is a 'View Insights' button.
  // We have a separate event for that
  if (
    !['ContentWrapper', 'ButtonContent', 'ButtonWrapper'].includes(elClassName)
  ) {
    sendCtaClickEvent({
      destination: 'modal',
      text: 'Table Row',
      location: getPathData(window.location?.hash?.substr(1))?.name
    })
  }
}

export const formatDataWithTrend = (t, parsedData) => {
  if (!parsedData?.length) {
    return []
  }

  const dataForTrend = parsedData.map(d => ({
    ...d,
    estimateEndDateValue: moment(d.estimateEndDate).valueOf() // need to convert time to seconds so that it can be used to calculate the trend
  }))

  const { calcY, slope } = createTrend(
    dataForTrend,
    'estimateEndDateValue',
    'estimatedExactSearchVolume'
  )

  const getTrendDirection = () => {
    if (slope === 0) {
      return t('common:HistoricalGraph.searchVolume.same', 'same')
    }
    return slope > 0
      ? t('common:HistoricalGraph.searchVolume.upwards', 'upwards')
      : t('common:HistoricalGraph.searchVolume.downwards', 'downwards')
  }

  return {
    chartData: dataForTrend.map(d => ({
      ...d,
      trendY: calcY(moment(d.estimateEndDate).valueOf())
    })),
    trend: getTrendDirection()
  }
}

export const trackApplyFiltersAction = ({ filters, selectedCategories }) => {
  safeSegmentCall(() => {
    const initialCategoriesLength =
      DEFAULT_KEYWORD_ENGINE_FILTERS.category?.valuesArray?.length

    sendSegmentTrackEvent('Keyword Scout Search Result Filtered', {
      product: PRODUCT_FOR_SEGMENT,
      category:
        initialCategoriesLength &&
        initialCategoriesLength === selectedCategories?.length
          ? 'All categories'
          : selectedCategories || filters?.category?.value,
      exactMatchMin: toNumeric(filters?.estimatedExactSearchVolume?.min),
      exactMatchMax: toNumeric(filters?.estimatedExactSearchVolume?.max),
      boadMatchMin: toNumeric(filters?.estimatedBroadSearchVolume?.min),
      boadMatchMax: toNumeric(filters?.estimatedBroadSearchVolume?.max),
      wordCountMin: toNumeric(filters?.wordCount?.min),
      wordCountMax: toNumeric(filters?.wordCount?.max),
      organicProductCountMin: toNumeric(filters?.resultCount?.min),
      organicProductCountMax: toNumeric(filters?.resultCount?.max),
      sponsoredProductCountMin: toNumeric(filters?.sponsoredProductCount?.min),
      sponsoredProductCountMax: toNumeric(filters?.sponsoredProductCount?.max),
      ppcBidExactMin: toNumeric(filters?.exactSuggestedBidMedian?.min),
      ppcBidExactMax: toNumeric(filters?.exactSuggestedBidMedian?.max),
      ppcBidBroadMin: toNumeric(filters?.broadSuggestedBidMedian?.min),
      ppcBidBroadMax: toNumeric(filters?.broadSuggestedBidMedian?.max),
      easeOfRankMin: toNumeric(filters?.easeOfRankingScore?.min),
      easeOfRankMax: toNumeric(filters?.easeOfRankingScore?.max),
      excludeKeywords: filters?.excludeKeywords?.searchTerm,
      includeKeywords: filters?.includeKeywords?.searchTerm
    })
  })
}
