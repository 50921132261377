import moment from 'moment'

import { DATABASE_TOOLTIPS } from 'constants/product_database/tooltips'

import { isValidValue } from 'helpers/values'
import { parseCurrency } from 'helpers/currency'
import { dimensionFormatter } from 'helpers/formatters'
import { snakeCaseToCapitalizedWords } from 'helpers/strings'
import { calcRevenue } from './core'

const NA_VALUE = 'N.A.'

export const formatValForCsv = (attribute, product) =>
  formatDatabaseValue(attribute, product).value

const isVariant = product =>
  product.scrapedParentAsin && product.scrapedParentAsin !== product.asin

// returns an object like { value: any, tooltip?: string, showValueInTooltip?: bool }
export const formatDatabaseValue = (attribute, product) => {
  switch (attribute) {
    case 'name':
      return formatProductName(product)
    case 'listingQualityScore':
      return formatProductListingQualityScore(product)
    case 'nSellers':
      return formatProductNumberOfSellers(product)
    case 'rank':
      return formatProductRank(product)
    case 'estimatedSales':
      if (isVariant(product) && product.isSharedBSR) {
        return { value: '--' }
      }

      return formatProductEstimatedSales(product)
    case 'estRevenue':
      if (isVariant(product) && product.isSharedBSR) {
        return { value: '--' }
      }

      return formatProductEstimatedRevenue(product)
    case 'price':
      return formatProductPrice(product)
    case 'fees':
      return formatProductFees(product)
    case 'net':
      return formatProductNet(product)
    case 'dimensions':
      return formatValidResponse(dimensionFormatter(product))
    case 'tier':
      return formatProductTier(product)
    case 'weight':
      return formatProductWeight(product)
    case 'listedAt':
      return formatProductListedAt(product)
    case 'variantDifferences':
      return formatVariantDifferences(product)
    default:
      const value = product[attribute]
      return isValidValue(value)
        ? formatValidResponse(value)
        : formatInvalidResponse()
  }
}

export const formatProductName = ({ name }) => {
  if (name) return formatValidResponse(name.replace(/,/g, ' '))

  return formatInvalidResponse()
}

export const formatProductListingQualityScore = ({ listingQualityScore }) => {
  if (!isValidValue(listingQualityScore))
    return formatInvalidResponse(DATABASE_TOOLTIPS.nullProductData.noLQS)

  return formatValidResponse(Math.round(listingQualityScore / 10))
}

export const formatProductNumberOfSellers = ({ nSellers }) =>
  formatValidResponse(nSellers || 1)

export const formatProductRank = ({ rank, bsr_product }) => {
  if (!bsr_product)
    return formatInvalidResponse(DATABASE_TOOLTIPS.nullProductData.noBsrRank)

  if (!isValidValue(rank) || isNaN(parseInt(rank, 10)))
    return formatInvalidResponse(DATABASE_TOOLTIPS.nullProductData.rank)

  return formatValidResponse(parseInt(rank, 10).toLocaleString())
}

export const formatProductEstimatedSales = ({
  estimatedSales,
  bsr_product,
  hasRankFromApi
}) => {
  if (!isValidValue(estimatedSales))
    return formatInvalidResponse(DATABASE_TOOLTIPS.nullProductData.noEstSales)

  if (!bsr_product)
    return formatInvalidResponse(
      DATABASE_TOOLTIPS.nullProductData.noBsrEstSales
    )

  const displayValue =
    estimatedSales < 5 ? '< 5' : parseInt(estimatedSales, 10).toLocaleString()

  if (hasRankFromApi)
    return formatInvalidResponse(
      DATABASE_TOOLTIPS.nullProductData.rankFromApi,
      displayValue
    )

  return formatValidResponse(displayValue)
}

export const formatProductEstimatedRevenue = ({
  estimatedSales,
  estRevenue,
  bsr_product,
  price,
  hasRankFromApi,
  currency_code,
  shouldHandleAccuracy = true,
  decimalPlaces
}) => {
  if (!isValidValue(estimatedSales) || !bsr_product)
    return formatInvalidResponse(
      DATABASE_TOOLTIPS.nullProductData.noSalesEstRevenue
    )

  if (!isValidValue(price))
    return formatInvalidResponse(
      DATABASE_TOOLTIPS.nullProductData.noPriceEstRevenue
    )

  const sales = parseInt(estimatedSales, 10)
  const revenue = calcRevenue({ estRevenue, price, sales })

  let currencyOptions = {}
  if (typeof decimalPlaces === 'number') {
    currencyOptions = {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces
    }
  }

  const formattedRevenue = parseCurrency(
    revenue,
    currency_code,
    currencyOptions
  )
  let displayValue = formattedRevenue

  if (shouldHandleAccuracy) {
    // TODO: The back-end should define (1) whether an estimate is accurate
    // or (2) the confidence level of an estimate. Remove/replace this block
    // when the back-end has that feature.
    displayValue = sales < 5 ? `< ${formattedRevenue}` : formattedRevenue
  }

  if (hasRankFromApi)
    return formatInvalidResponse(
      DATABASE_TOOLTIPS.nullProductData.rankFromApi,
      displayValue
    )

  return formatValidResponse(displayValue)
}

export const formatProductPrice = ({ price, currency_code }) => {
  if (isValidValue(price))
    return formatValidResponse(parseCurrency(price, currency_code))

  return formatInvalidResponse(DATABASE_TOOLTIPS.nullProductData.price)
}

export const formatProductFees = ({ fees, currency_code }) => {
  if (isValidValue(fees))
    return formatValidResponse(parseCurrency(fees, currency_code))

  return formatInvalidResponse(DATABASE_TOOLTIPS.nullProductData.noFees)
}

export const formatProductNet = ({ price, fees, currency_code }) => {
  if (isValidValue(fees) && isValidValue(price))
    return formatValidResponse(parseCurrency(price - fees, currency_code))

  if (!isValidValue(fees))
    return formatInvalidResponse(DATABASE_TOOLTIPS.nullProductData.noFeesNet)

  return formatInvalidResponse(DATABASE_TOOLTIPS.nullProductData.noPriceNet)
}

export const formatProductTier = ({ tier }) => {
  if (typeof tier === 'string') return formatValidResponse(tier)

  return formatInvalidResponse(DATABASE_TOOLTIPS.nullProductData.tier)
}

export const formatProductWeight = ({ weight, weightUnit }) => {
  if (weight || weight === 0) {
    const unit = weightUnit === 'kilograms' ? 'kg' : 'lbs'

    return formatValidResponse(`${weight} ${unit}`)
  }

  return formatInvalidResponse(DATABASE_TOOLTIPS.nullProductData.weight)
}

const formatValidResponse = value => {
  return { value }
}

const formatInvalidResponse = (tooltip = null, value = null) => {
  return { tooltip, value: value || NA_VALUE, showValueInTooltip: !!value }
}

export const formatProductListedAt = ({ listedAt, estimatedListedAt }) => {
  if (listedAt)
    return formatValidResponse(moment(parseInt(listedAt)).format('MM/DD/YYYY'))

  if (estimatedListedAt)
    return formatValidResponse(
      moment(parseInt(estimatedListedAt)).format('MM/DD/YYYY')
    )

  return formatInvalidResponse(DATABASE_TOOLTIPS.nullProductData.listedAt)
}

export const formatVariantDifferences = product => {
  if (isVariant(product)) {
    return {
      value:
        product?.dimensionValuesDisplayData[0]?.differences?.join(', ') ||
        NA_VALUE
    }
  }

  if (product?.dimensions?.length > 0) {
    const value = product.dimensions.map(snakeCaseToCapitalizedWords).join(', ')
    return { value }
  }

  return { value: NA_VALUE }
}
