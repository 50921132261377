import i18n from 'services/i18n'
import moment from 'moment'
import Big from 'big.js'

import { parseCurrency, getCurrencySymbolFromCode } from 'helpers/currency'
import {
  formatDateAxisMonthDayFull,
  getDateRange,
  groupDataByDay,
  groupDataByMonth,
  groupDataByWeek,
  groupDataByWeekAndTrim
} from 'helpers/charts'
import {
  humanizeLongNumber,
  humanizeLongNumberWithLocale,
  percentageFormatter
} from 'helpers/formatters'
import { sumKeys, avgKeys, avgRating, avgKeysCurrency } from 'helpers/objects'
import { CHART_TOOLTIP_CONFIG } from 'constants/competitive_intelligence/common'

export const getAxisLabel = metric => {
  switch (metric) {
    case 'market_share':
      return i18n.t('common:MetricName.marketShare', 'Market Share')
    case 'revenue':
      return i18n.t('common:MetricName.revenue', 'Revenue')
    case 'units_sold':
      return i18n.t('common:MetricName.unitsSold', 'Units Sold')
    case 'avg_price':
      return i18n.t('common:MetricName.avgPrice', 'Avg Price')
    case 'star_rating':
      return i18n.t('common:MetricName.starRating', 'Star Rating')
    case 'reviews':
      return i18n.t('common:MetricName.reviews', 'Reviews')
    default:
      return i18n.t('common:MetricName.revenue', 'Revenue')
  }
}

export const getChartTitle = metric => {
  switch (metric) {
    case 'revenue':
      return i18n.t(
        'competitive_intelligence:OverviewTab.revenueOverTime',
        'Revenue Over Time'
      )
    case 'units_sold':
      return i18n.t(
        'competitive_intelligence:OverviewTab.unitsSoldOverTime',
        'Units Sold Over Time'
      )
    case 'avg_price':
      return i18n.t(
        'competitive_intelligence:OverviewTab.avgPriceOverTime',
        'Avg Price Over Time'
      )
    case 'star_rating':
      return i18n.t(
        'competitive_intelligence:OverviewTab.starRatingOverTime',
        'Star Rating Over Time'
      )
    case 'reviews':
      return i18n.t(
        'competitive_intelligence:OverviewTab.reviewsOverTime',
        'Reviews Over Time'
      )
    case 'market_share':
      return i18n.t(
        'competitive_intelligence:OverviewTab.marketShareOverTime',
        'Market Share Over Time'
      )
    default:
      return i18n.t(
        'competitive_intelligence:OverviewTab.revenueOverTime',
        'Revenue Over Time'
      )
  }
}

const safeBig = value => {
  if (
    value === null ||
    value === undefined ||
    value === '' ||
    isNaN(value) ||
    !isFinite(value)
  ) {
    return Big(0) // Default to 0 if the value is invalid
  }
  return Big(value) // Proceed with valid values
}

export const metricFormatter = (value, metric) => {
  const bigValue = safeBig(value)

  if (metric === 'revenue') {
    return parseCurrency(bigValue.toFixed(0), 'USD', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })
  }

  if (metric === 'avg_price') {
    return parseCurrency(bigValue.toFixed(2), 'USD', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  }

  if (metric === 'star_rating') {
    return humanizeLongNumberWithLocale(bigValue.toFixed(1))
  }

  if (metric === 'market_share') {
    return percentageFormatter(bigValue.times(100).toFixed(2))
  }

  return humanizeLongNumberWithLocale(
    bigValue.round(0, Big.roundHalfUp).toNumber()
  )
}

export const metricYAxisFormatter = (value, metric) => {
  const formatted = `${humanizeLongNumber(value)}`.toUpperCase()

  if (['revenue', 'avg_price'].includes(metric)) {
    return `${getCurrencySymbolFromCode()}${formatted}`
  }

  if (['units_sold', 'reviews'].includes(metric)) {
    return formatted
  }

  return parseFloat(value).toFixed(0)
}

export const calculateMarketShare = ({ data }) => {
  const intervalRevenue = {}

  Object.keys(data).forEach(currInterval => {
    const intervalData = data[currInterval]
    const totalIntervalRevenue = intervalData
      .map(item => safeBig(item.revenue))
      .reduce((acc, revenue) => acc.plus(revenue), safeBig(0))
    intervalRevenue[currInterval] = totalIntervalRevenue
  })

  Object.keys(data).forEach(currInterval => {
    const intervalData = data[currInterval]
    const pinnedIntervalData = intervalData.filter(item => item.is_pinned)

    pinnedIntervalData.forEach(item => {
      const divisor = intervalRevenue[currInterval].eq(0)
        ? Big(1)
        : intervalRevenue[currInterval]

      const marketShare = safeBig(item.revenue)
        .div(divisor)
        .toNumber()

      item.market_share = marketShare
    })
  })

  return data
}

export const formatTrendChartData = ({
  data,
  interval,
  trim = false,
  isMarketShare = false
}) => {
  if (!data || data.length === 0) return []

  let groupedData = {}
  let forcedInterval = null

  switch (interval) {
    case 'daily':
      groupedData = groupDataByDay(data, 'x')
      break
    case 'weekly':
      groupedData = trim
        ? groupDataByWeekAndTrim(data, 'x')
        : groupDataByWeek(data, 'x')
      // on trend graphs, we want to force weekly interval on the x-axis
      forcedInterval = 'monthly'
      break
    case 'monthly':
      groupedData = groupDataByMonth(data, 'x')
      break
    default:
      break
  }

  if (isMarketShare) groupedData = calculateMarketShare({ data: groupedData })

  const keysToSum = ['market_share', 'revenue', 'units_sold']

  return Object.values(groupedData).map(group => {
    const dateRange = getDateRange({ data: group, interval, forcedInterval })
    const result = {
      ...dateRange,
      ...sumKeys(group, keysToSum)
    }

    return result
  })
}

export const formatChartData = ({ data, interval, trim = false }) => {
  if (!data || data.length === 0) return []

  if (interval === 'daily') {
    return data.map(item => {
      const formattedDate = formatDateAxisMonthDayFull(item.date)
      return {
        ...item,
        formattedDate
      }
    })
  }

  let groupedData = {}

  if (interval === 'weekly') {
    groupedData = trim
      ? groupDataByWeekAndTrim(data, 'x')
      : groupDataByWeek(data, 'x')
  } else if (interval === 'monthly') {
    groupedData = groupDataByMonth(data, 'x')
  }

  const dataKeys = Object.keys(data[0]).filter(key => key !== 'date') // ['avg_price', 'revenue', 'reviews', 'star_rating', 'units_sold', 'market_share']

  const keysToSum = []
  const keysToAvg = []
  const keysToAvgCurrency = []
  const keysToAvgRating = []

  dataKeys.forEach(key => {
    const metric = key.split('-')[0]

    switch (metric) {
      case 'revenue':
      case 'units_sold':
        keysToSum.push(key)
        break
      case 'reviews':
      case 'market_share':
        keysToAvg.push(key)
        break
      case 'avg_price':
        keysToAvgCurrency.push(key)
        break
      case 'star_rating':
        keysToAvgRating.push(key)
        break
      default:
        break
    }
  })

  // Use safeBig and Big.js methods for arithmetic
  return Object.values(groupedData).map(group => {
    const dateRange = getDateRange({ data: group, interval })

    const mergedGroup = {
      ...sumKeys(
        group.map(item =>
          Object.fromEntries(
            keysToSum.map(key => [key, safeBig(item[key]).toNumber()])
          )
        ),
        keysToSum
      ),
      ...avgKeys(
        group.map(item =>
          Object.fromEntries(
            keysToAvg.map(key => [key, safeBig(item[key]).toNumber()])
          )
        ),
        keysToAvg,
        true
      ),
      ...avgKeysCurrency(
        group.map(item =>
          Object.fromEntries(
            keysToAvgCurrency.map(key => [key, safeBig(item[key]).toNumber()])
          )
        ),
        keysToAvgCurrency,
        true
      ),
      ...avgRating(
        group.map(item =>
          Object.fromEntries(
            keysToAvgRating.map(key => [key, safeBig(item[key]).toNumber()])
          )
        ),
        keysToAvgRating,
        true
      )
    }

    return {
      ...dateRange,
      ...mergedGroup
    }
  })
}

export const calculateGroupAverages = data => {
  return data?.reduce((acc, item, index, array) => {
    let revenue = acc.revenue ? acc.revenue + item.revenue : item.revenue
    let market_share = acc.market_share
      ? acc.market_share + item.market_share
      : item.market_share
    let units_sold = acc.units_sold
      ? acc.units_sold + item.units_sold
      : item.units_sold
    let avg_price = acc.avg_price
      ? acc.avg_price + item.avg_price
      : item.avg_price
    let star_rating = acc.star_rating
      ? acc.star_rating + item.star_rating
      : item.star_rating
    let reviews = acc.reviews ? acc.reviews + item.reviews : item.reviews

    if (index === array.length - 1) {
      revenue /= array.length
      market_share /= array.length
      units_sold /= array.length
      avg_price /= array.length
      star_rating /= array.length
      reviews /= array.length
    }

    return {
      revenue,
      market_share,
      units_sold,
      avg_price,
      star_rating,
      reviews
    }
  }, {})
}

const findMedian = arr => {
  const sortedArr = arr.slice().sort((a, b) => a - b)
  const middleIndex = Math.floor(sortedArr.length / 2)

  if (sortedArr.length % 2 === 0) {
    return (sortedArr[middleIndex - 1] + sortedArr[middleIndex]) / 2
  }

  return sortedArr[middleIndex]
}

export const calculateGroupMedians = data => {
  const revenue = data.map(item => item.revenue)
  const market_share = data.map(item => item.market_share)
  const units_sold = data.map(item => item.units_sold)
  const avg_price = data.map(item => item.avg_price)
  const star_rating = data.map(item => item.star_rating)
  const reviews = data.map(item => item.reviews)

  return {
    revenue: findMedian(revenue),
    market_share: findMedian(market_share),
    units_sold: Math.round(findMedian(units_sold)),
    avg_price: findMedian(avg_price),
    star_rating: findMedian(star_rating),
    reviews: Math.round(findMedian(reviews))
  }
}

const getRank = (asinRankData, rankTypeFilter) => {
  const { absolute, organic, sponsored } = asinRankData?.topProducts || {}

  switch (rankTypeFilter) {
    case 'allKeywords':
      return absolute?.rank
    case 'organicOnly':
      return organic?.rank
    case 'sponsoredOnly':
      return sponsored?.rank
    default:
      return absolute?.rank
  }
}

const comparableRank = rank => {
  if (rank === null || rank === undefined) {
    return Infinity
  }
  return rank
}

const isAdOpportunity = (primaryProductRankData, competitorProductRankData) => {
  // Ad Opportunity has to ignore the rank type filter, since we need to look at both types
  const primaryOrganicRank = comparableRank(
    getRank(primaryProductRankData, 'organicOnly')
  )
  const competitorOrganicRanks = competitorProductRankData.map(asinRank =>
    getRank(asinRank, 'organicOnly')
  )
  const competitorSponsoredRanks = competitorProductRankData.map(asinRank =>
    getRank(asinRank, 'sponsoredOnly')
  )

  const competitorsAreNotSponsoring = competitorSponsoredRanks.every(
    rank => rank === null || rank === undefined
  )

  const competitorsAreBeatingPrimaryOrganic = competitorOrganicRanks.every(
    rank => comparableRank(rank) < primaryOrganicRank
  )

  const competitorsAreWellRanked = competitorOrganicRanks.every(
    rank => comparableRank(rank) < 25
  )

  return (
    competitorsAreNotSponsoring &&
    competitorsAreBeatingPrimaryOrganic &&
    competitorsAreWellRanked
  )
}

export const filterKeywords = (
  keywords,
  filter,
  rankTypeFilter,
  tableProducts
) => {
  return keywords.filter(keyword => {
    const primaryProductAsin = tableProducts[0]
    const primaryProductRankData =
      keyword?.asinRanks?.find(
        asinRank => asinRank.asin === primaryProductAsin
      ) || {}
    const primaryProductRank = getRank(primaryProductRankData, rankTypeFilter)
    const comparablePrimaryProductRank = comparableRank(primaryProductRank)

    const competitorProducts = tableProducts.slice(1)
    const competitorProductRankData =
      keyword?.asinRanks?.filter(asinRank =>
        competitorProducts.includes(asinRank.asin)
      ) || []

    const competitorProductsRanks = competitorProductRankData.map(asinRank =>
      getRank(asinRank, rankTypeFilter)
    )
    const comparableCompetitorProductsRanks = competitorProductsRanks.map(
      comparableRank
    )

    switch (filter) {
      case 'shared':
        // All products have a rank
        return primaryProductRank && competitorProductsRanks.every(rank => rank)
      case 'missing':
        // Primary product does not rank, and all competitors have a rank
        return (
          !primaryProductRank &&
          competitorProductsRanks.every(rank => rank) &&
          competitorProductsRanks?.length > 0
        )
      case 'weak':
        // If primary product has a rank, it should be lower than all competitor products ranks
        return (
          primaryProductRank &&
          comparableCompetitorProductsRanks.every(
            rank => rank !== Infinity && rank < primaryProductRank
          )
        )
      case 'strong':
        // Primary product has a rank, and it is higher than all competitor products
        return (
          primaryProductRank &&
          comparablePrimaryProductRank <
            Math.min(...comparableCompetitorProductsRanks)
        )
      case 'advertising':
        return isAdOpportunity(
          primaryProductRankData,
          competitorProductRankData
        )
      case 'all':
      default:
        return true
    }
  })
}

export const sortKeywordsTable = (keywords, sortColumn, sortDirection) => {
  if (sortColumn === 'isAmazonChoice') {
    return keywords?.sort((a, b) => {
      const aIsPrimary = a.asinRanks[0]?.isAmazonChoice || false
      const bIsPrimary = b.asinRanks[0]?.isAmazonChoice || false

      const aIsSecondary = a.asinRanks
        .slice(1)
        .some(rank => rank.isAmazonChoice)
      const bIsSecondary = b.asinRanks
        .slice(1)
        .some(rank => rank.isAmazonChoice)

      if (sortDirection === 'asc') {
        if (!aIsPrimary && bIsPrimary) return -1
        if (aIsPrimary && !bIsPrimary) return 1

        if (!aIsSecondary && bIsSecondary) return -1
        if (aIsSecondary && !bIsSecondary) return 1

        return 0
      }

      if (aIsPrimary && !bIsPrimary) return -1
      if (!aIsPrimary && bIsPrimary) return 1

      if (aIsSecondary && !bIsSecondary) return -1
      if (!aIsSecondary && bIsSecondary) return 1

      return 0
    })
  }

  return keywords?.sort((a, b) => {
    const valueA = a[sortColumn]
    const valueB = b[sortColumn]

    if (
      (valueA === null || valueA === undefined) &&
      (valueB === null || valueB === undefined)
    ) {
      return 0
    }

    if (valueA === null || valueA === undefined) {
      return 1
    }

    if (valueB === null || valueB === undefined) {
      return -1
    }

    const nullRankCharacter = '-'
    if (sortDirection === 'asc') {
      if (valueA === nullRankCharacter) return 1
      if (valueB === nullRankCharacter) return -1
      return valueA > valueB ? 1 : -1
    }

    if (valueA === nullRankCharacter) return -1
    if (valueB === nullRankCharacter) return 1
    return valueA < valueB ? 1 : -1
  })
}

export const emptyStateMessage = (filter, competitorsCount) => {
  if (competitorsCount === 0 && filter !== 'all') {
    return i18n.t(
      'competitive_intelligence:KeywordAnalysis.emptyState.noCompetitor',
      'Select at least one competitor to compare product keyword rankings.'
    )
  }

  if (competitorsCount >= 1 && competitorsCount <= 2) {
    return i18n.t(
      'competitive_intelligence:KeywordAnalysis.emptyState.sharedKeywords',
      'Choose competitors that are relevant to your product - they’re likely to share common keywords!'
    )
  }

  if (competitorsCount >= 4 && competitorsCount <= 6 && filter !== 'all') {
    return i18n.t(
      'competitive_intelligence:KeywordAnalysis.emptyState.optimalSelection',
      'For optimal results, try narrowing down your selection of competitors!'
    )
  }

  return i18n.t(
    'generic:EmptyState.noResults.description',
    'Please change your filter criteria and try again'
  )
}

export const getRecommendationText = filter => {
  switch (filter) {
    case 'shared':
      return i18n.t(
        'competitive_intelligence:KeywordAnalysis.Opportunities.filter.sharedRecommendation',
        'These are the most important keywords for which all the selected products compete.'
      )
    case 'missing':
      return i18n.t(
        'competitive_intelligence:KeywordAnalysis.Opportunities.filter.missingRecommendation',
        'The selected competitors think these keywords are valuable, consider targeting these keywords.'
      )
    case 'weak':
      return i18n.t(
        'competitive_intelligence:KeywordAnalysis.Opportunities.filter.weakRecommendation',
        'The primary product is competing for these keywords but is outperformed. Consider improving the strategy to compete more effectively.'
      )
    case 'strong':
      return i18n.t(
        'competitive_intelligence:KeywordAnalysis.Opportunities.filter.strongRecommendation',
        'The primary product is winning for these keywords. The current strategy is effective. Nice Job!'
      )
    case 'advertising':
      return i18n.t(
        'competitive_intelligence:KeywordAnalysis.Opportunities.filter.adOpportunityRecommendation',
        'Competitors have high organic rankings but are not defending their positions with ads. This indicates that these keywords could present an advertising opportunity for you to capitalize on.'
      )
    default:
      return ''
  }
}

export const getMinMaxDatesCalendar = () => {
  const maxDate = moment()
    .subtract(1, 'week')
    .endOf('week')
    .startOf('day')

  const minDate = maxDate
    .clone()
    .subtract(365, 'days')
    .toDate()

  return { minDate, maxDate: maxDate.toDate() }
}

export const formatDate = value => {
  const date = moment(value)

  if (value === undefined || value === null || !date.isValid()) {
    return '-'
  }
  return date.format('MMMM DD, YYYY')
}

export const matchRankTypeFilter = rankTypeFilter => {
  switch (rankTypeFilter) {
    case 'allKeywords':
      return 'absolute'
    case 'organicOnly':
      return 'organic'
    case 'sponsoredOnly':
      return 'sponsored'
    default:
      return 'absolute'
  }
}

export const extendKeywordsWithRankData = (
  keywords,
  products,
  rankTypeFilter,
  variantMetadata
) =>
  keywords.map(keyword => {
    const keywordCopy = { ...keyword }
    if (products?.length) {
      products.forEach((product, index) => {
        const asinRankData = keyword?.asinRanks?.find(asinRank => {
          return asinRank.asin === product.id.split('/')[1]
        })
        const filter = matchRankTypeFilter(rankTypeFilter)

        const { rank, asin, type } = asinRankData?.topProducts?.[filter] || {}
        const topProduct = variantMetadata?.[asin] || {}

        keywordCopy[`productRank-${index}`] = !Number(rank) ? '-' : rank
        keywordCopy[`topProduct-${index}`] = {
          ...topProduct,
          marketplace: keyword.country,
          type,
          isSingleProduct: !product.variantAsinsCount && !product.variantCount
        }
      })
    }
    return keywordCopy
  })

export const isChartTooltipExceedingPage = ({ left }) => {
  const pageBuffer = 20

  return (
    left + CHART_TOOLTIP_CONFIG.width + CHART_TOOLTIP_CONFIG.offset >
    window.innerWidth - pageBuffer
  )
}

export const updateKeywordsCallback = (queryClient, groupId, keyword_list) => {
  queryClient.invalidateQueries(`api/rank_groups/${groupId}/keywords`)
  queryClient.setQueryData(
    [
      `api/rank_groups/${groupId}`,
      {
        data: {
          is_for_demo: false
        }
      }
    ],
    prevData => {
      return {
        ...prevData,
        data: {
          ...prevData.data,
          keyword_list
        }
      }
    }
  )
}

const calculateRankPosition = rank => {
  if (rank == null) return []
  if (rank <= 5) return ['5', '10', '50']
  if (rank <= 10) return ['10', '50']
  if (rank <= 50) return ['50']
  return []
}

export const dateValueByLabel = label => {
  const endDate = moment()
    .subtract(1, 'days')
    .format('YYYY-MM-DD')

  const labelMapping = {
    '7D': {
      label: '7D',
      startDate: moment()
        .subtract(7, 'days')
        .format('YYYY-MM-DD'),
      endDate
    },
    '30D': {
      label: '30D',
      startDate: moment()
        .subtract(30, 'days')
        .format('YYYY-MM-DD'),
      endDate
    }
  }

  return labelMapping[label] || labelMapping['7D']
}

const getRankPosition = (keyword, filterType) => {
  const rank = keyword?.[filterType]
  if (rank?.previous != null && rank?.current != null) {
    return {
      previousRankPositions: calculateRankPosition(rank.previous),
      currentRanksPositions: calculateRankPosition(rank.current)
    }
  }
  return { previousRankPositions: [], currentRanksPositions: [] }
}

export function formatRankDistributionData(distributionData, filter) {
  const tracker = {
    '5': {
      id: 'top5',
      name: 5,
      totalKeyword: 0,
      totalNetChange: 0,
      totalGainedChange: 0,
      totalLostChange: 0,
      gainedKeywords: [],
      lostKeywords: []
    },
    '10': {
      id: 'top10',
      name: 10,
      totalKeyword: 0,
      totalNetChange: 0,
      totalGainedChange: 0,
      totalLostChange: 0,
      gainedKeywords: [],
      lostKeywords: []
    },
    '50': {
      id: 'top50',
      name: 50,
      totalKeyword: 0,
      totalNetChange: 0,
      totalGainedChange: 0,
      totalLostChange: 0,
      gainedKeywords: [],
      lostKeywords: []
    }
  }

  if (!distributionData) {
    return []
  }

  distributionData.forEach(keyword => {
    let rankPositions = { previousRankPositions: [], currentRanksPositions: [] }

    switch (filter) {
      case 'organicOnly':
        rankPositions = getRankPosition(keyword, 'organicRank')
        break
      case 'sponsoredOnly':
        rankPositions = getRankPosition(keyword, 'sponsoredRank')
        break
      default:
        rankPositions = getRankPosition(keyword, 'absoluteRank')
        break
    }

    const { previousRankPositions, currentRanksPositions } = rankPositions

    // Skip if either previous or current is empty
    if (
      previousRankPositions.length === 0 ||
      currentRanksPositions.length === 0
    ) {
      return
    }

    currentRanksPositions.forEach(position => {
      tracker[position].totalKeyword += 1
    })

    previousRankPositions.forEach(prevPosition => {
      if (!currentRanksPositions.includes(prevPosition)) {
        tracker[prevPosition].totalLostChange += 1
        tracker[prevPosition].lostKeywords.push(keyword)
      }
    })

    currentRanksPositions.forEach(currPosition => {
      if (!previousRankPositions.includes(currPosition)) {
        tracker[currPosition].totalGainedChange += 1
        tracker[currPosition].gainedKeywords.push(keyword)
      }
    })
  })

  Object.keys(tracker).forEach(position => {
    tracker[position].totalNetChange =
      tracker[position].totalGainedChange - tracker[position].totalLostChange
    tracker[position].totalLostChange = -tracker[position].totalLostChange
  })

  return Object.values(tracker)
}

export const unavailableProductMock = ({ asin, marketplace }) => {
  return {
    imageUrl: null,
    asin,
    category: '-',
    brand: '-',
    isParent: false,
    scrapedParentAsin: '-',
    id: `${marketplace}/${asin}`,
    name: 'Unavailable Product',
    variantAsinsCount: 0,
    categoryCode: '-',
    is_supported_category: false,
    error: 'unavailable_product'
  }
}

export const isUpgradeIncentiveEligible = ({
  billingFrequency,
  status,
  subscriptionDate,
  planId
}) => {
  return (
    billingFrequency === 'monthly' &&
    status === 'active' &&
    moment
      .utc(subscriptionDate)
      .isBetween('2024-01-01', '2024-11-01', 'day', '[)') &&
    [743, 700].includes(planId)
  )
}

/**
 * Formats rank performance data for the rank performance graph
 * Input: { data: [{ date: '2024-01-01', absolute: 1, organic: 2, sponsored: 3, asinGroupKey: B0HJK43TJ0 },
 *                 { date: '2024-01-01', absolute: 4, organic: 5, sponsored: 5, asinGroupKey: B0HJKHJ8HD }], ... ]}
 * Output: [{ date: '2024-01-01', absolute-B0HJK43TJ0: 1, organic-B0HJK43TJ0: 2, sponsored-B0HJK43TJ0: 3,
 *                                absolute-B0HJKHJ8HD: 4, organic-B0HJKHJ8HD: 5, sponsored-B0HJKHJ8HD: 6... }, ...]
 * @param data
 * @returns {*[]}
 */
export const formatRankPerformanceData = ({ data, interval }) => {
  const transformedData = []
  const dates = new Set()

  // Extract all unique dates
  data.forEach(item => dates.add(item.date))

  // Iterate over each unique date
  dates.forEach(date => {
    const dateEntry = { date }

    // Iterate over each entry in the data
    data.forEach(item => {
      if (item.date === date) {
        const { asinGroupKey } = item
        dateEntry[`absolute-${asinGroupKey}`] = item.absolute
        dateEntry[`organic-${asinGroupKey}`] = item.organic
        dateEntry[`sponsored-${asinGroupKey}`] = item.sponsored
      }
    })

    transformedData.push(dateEntry)
  })

  const groupedData =
    interval === 'weekly'
      ? groupDataByWeek(transformedData)
      : groupDataByMonth(transformedData)

  const aggregateData = Object.values(groupedData).map(group => {
    if (group.length === 0) return {}

    const dateRange = getDateRange({ data: group, interval })
    const keysToAvg = Object.keys(group[0]).filter(key => key !== 'date')

    const result = {
      ...dateRange,
      ...(keysToAvg.length > 0 ? avgKeys(group, keysToAvg) : {})
    }

    return result
  })

  return aggregateData
}
