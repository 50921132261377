import { buildMenuItems } from 'helpers/menu_item_builder'
import React, { useEffect, useState } from 'react'
import { slide as Menu } from 'react-burger-menu'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { COLORS } from 'COLORS'

import { JungleScoutLogo } from 'icons/JungleScoutLogo/JungleScoutLogoV2'
import burgerIcon from 'icons/svg/mobile-hamburger-icon.svg'

import { MobileMenuItem } from './MobileMenuItem'
import { AmazonAccountDropdown } from 'components/navbar/AmazonAccountDropdown/AmazonAccountDropdown'
import { ClickableIcon } from '@junglescout/edna'

const BASE_Z_INDEX = 100000

const AccountSelectorWrapper = styled.div`
  padding: 12px 16px;
`
const CloseButton = styled.div`
  position: fixed;
  right: 10px;
  top: 6px;
  z-index: ${BASE_Z_INDEX + 1};
`
const ItemsWrapper = styled.div`
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;
  flex-grow: 1;
  .spacer {
    flex-grow: 1;
  }
`
const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
  width: 100%;
  height: 48px;
  min-height: 48px;
  background-color: black;
  transition: background-color 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);

  &:hover {
    cursor: ${props => (props.disabled ? '' : 'pointer')};
  }
`

const styles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '36px',
    height: '32px',
    left: '8px',
    top: '8px',
    padding: '8px', // Increase click area of burger button
    zIndex: BASE_Z_INDEX
  },
  bmMenuWrap: {
    position: 'fixed',
    height: window.CSS?.supports?.('height', '100dvh') ? '100dvh' : '100vh',
    zIndex: BASE_Z_INDEX
  },
  bmMenu: {
    background: COLORS.grey900
  },
  bmMorphShape: {
    fill: COLORS.grey900
  },
  bmItemList: {
    display: 'flex',
    flexDirection: 'column'
  },
  bmItem: {
    display: 'inline-block'
  },
  bmOverlay: {
    background: 'rgba(34, 34, 34, 0.7)',
    zIndex: BASE_Z_INDEX,
    backdropFilter: 'blur(2px)',
    width: '100vw',
    height: '100vh'
  }
}

const navigateHome = () => {
  window.location.hash = '/dashboard'
}

export const MobileMenu = ({
  expandedOption,
  admin,
  flagData,
  membershipInfo,
  expandSidebarOption,
  currentPath,
  user: { permissions, created_at },
  theme,
  enableWrapping
}) => {
  const { t } = useTranslation()
  const [menuOpen, setMenuOpen] = useState(false)
  const globalData = useSelector(state => state.globalData)

  // Disable background scrolling when menu is open
  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = 'hidden'
      document.documentElement.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
      document.documentElement.style.overflow = ''
    }

    // CLean up on unmount
    return () => {
      document.body.style.overflow = ''
      document.documentElement.style.overflow = ''
    }
  }, [menuOpen])

  const buildMenuItemsProps = {
    t,
    created_at,
    flagData,
    hasActiveMembership: membershipInfo.hasActiveMembership,
    featureLimits: globalData?.featureLimits
  }

  const renderItem = (item, key) => {
    return (
      <MobileMenuItem
        key={`menu${key}`}
        item={item}
        expandedOption={expandedOption}
        setMenuOpen={setMenuOpen}
        admin={admin}
        flagData={flagData}
        membershipInfo={membershipInfo}
        currentPath={currentPath}
        expandSidebarOption={expandSidebarOption}
        enableWrapping={enableWrapping}
        permissions={permissions}
      />
    )
  }

  const menuItems = buildMenuItems(buildMenuItemsProps)
  const primaryItems = menuItems.filter(item => !item.secondary)
  const secondaryItems = menuItems.filter(item => item.secondary)

  return (
    <>
      <Menu
        styles={styles}
        customCrossIcon={false}
        customBurgerIcon={<img src={burgerIcon} />}
        onOpen={() => {
          setMenuOpen(true)
        }}
        onClose={() => {
          setMenuOpen(false)
        }}
        isOpen={menuOpen}>
        <>
          <LogoWrapper theme={theme} disabled={false} onClick={navigateHome}>
            <JungleScoutLogo />
          </LogoWrapper>
          <AccountSelectorWrapper>
            <AmazonAccountDropdown
              isMobile
              onCtaClick={() => setMenuOpen(false)}
            />
          </AccountSelectorWrapper>
          <ItemsWrapper>
            {primaryItems.map(renderItem)}
            <div className="spacer" />
            {secondaryItems.map(renderItem)}
          </ItemsWrapper>
        </>
      </Menu>
      {menuOpen && (
        <CloseButton>
          <ClickableIcon
            name="X_CLOSE"
            color="white"
            onClick={() => setMenuOpen(false)}
          />
        </CloseButton>
      )}
    </>
  )
}
