import { t } from 'services/i18n'

export const KEYWORD_ENGINE_TOOLTIPS = {
  historicalSearchTrends: t(
    'constants:KEYWORD_ENGINE_TOOLTIPS.historicalSearchTrends',
    'Keyword Search Volume trend over the last 30 days.'
  ),
  estimatedExactSearchVolume: t(
    'constants:KEYWORD_ENGINE_TOOLTIPS.estimatedExactSearchVolume',
    'The number of Amazon searches over the past month that exactly match the keyword, excluding plurals and misspellings.'
  ),
  updatedAt: t(
    'constants:KEYWORD_ENGINE_TOOLTIPS.updatedAt',
    'The date of the last volume search update.'
  ),
  monthlyTrend: t(
    'constants:KEYWORD_ENGINE_TOOLTIPS.monthlyTrend',
    'Keyword search volume over the last 30 days vs. the prior 30 days.'
  ),
  quarterlyTrend: t(
    'constants:KEYWORD_ENGINE_TOOLTIPS.quarterlyTrend',
    'Keyword search volume over the last 90 days vs. the prior 90 days.'
  ),
  estimatedBroadSearchVolume: t(
    'constants:KEYWORD_ENGINE_TOOLTIPS.estimatedBroadSearchVolume',
    'The number of Amazon searches over the past month that broadly match the keyword, including the phrase, plurals, misspellings or synonyms.'
  ),
  category: t(
    'constants:KEYWORD_ENGINE_TOOLTIPS.category',
    'The most popular parent category across the top 10 ranked listings.'
  ),
  avgGiveaway: t(
    'constants:KEYWORD_ENGINE_TOOLTIPS.avgGiveaway',
    'The estimated number of daily promotions required to reach a top 3 ranking in 7-14 days.'
  ),
  exactSuggestedBidMedian: t(
    'constants:KEYWORD_ENGINE_TOOLTIPS.exactSuggestedBidMedian',
    'The estimated median cost of an exact match PPC ad.'
  ),
  broadSuggestedBidMedian: t(
    'constants:KEYWORD_ENGINE_TOOLTIPS.broadSuggestedBidMedian',
    'The estimated median cost of a broad match PPC ad.'
  ),
  easeOfRankingScore: t(
    'constants:KEYWORD_ENGINE_TOOLTIPS.easeOfRankingScore',
    'How easy it is for a new product to rank for this keyword.'
  ),
  relevancyScore: t(
    'constants:KEYWORD_ENGINE_TOOLTIPS.relevancyScore',
    'A high score indicating how closely related the keyword is to the seed keyword.'
  ),
  organicProductCount: t(
    'constants:KEYWORD_ENGINE_TOOLTIPS.organicProductCount',
    'Total number of Products returned on Amazon for this keyword.'
  ),
  sponsoredProductCount: t(
    'constants:KEYWORD_ENGINE_TOOLTIPS.sponsoredProductCount',
    'Total number of sponsored products recently detected for the keyword.'
  ),
  organicRank: t(
    'constants:KEYWORD_ENGINE_TOOLTIPS.organicRank',
    'Organic position of the highlighted ASIN. This rank ignores sponsored ASINs.'
  ),
  sponsoredRank: t(
    'constants:KEYWORD_ENGINE_TOOLTIPS.sponsoredRank',
    'Sponsored position of the highlighted ASIN. This count ignores organic ASINs.'
  ),
  overallRank: t(
    'constants:KEYWORD_ENGINE_TOOLTIPS.overallRank',
    "Overall Rank is the highlighted ASIN's overall positioning in the search results. This rank includes both organic and sponsored products."
  ),
  organicRankingAsins: t(
    'constants:KEYWORD_ENGINE_TOOLTIPS.organicRankingAsins',
    'Number of searched competitor ASINs, excluding the highlighted ASIN, that rank in the top 100 organic products for the keyword.'
  ),
  sponsoredRankingAsins: t(
    'constants:KEYWORD_ENGINE_TOOLTIPS.sponsoredRankingAsins',
    'Number of searched competitor ASINs, excluding the highlighted ASIN, that rank in the top 100 sponsored products for the keyword.'
  ),
  avgCompetitorOrganicRank: t(
    'constants:KEYWORD_ENGINE_TOOLTIPS.avgCompetitorOrganicRank',
    'Average organic rank for the ranking competitor ASINs, excluding the highlighted ASIN. How well your searched ASINs are ranking for this keyword on average.'
  ),
  avgCompetitorSponsoredRank: t(
    'constants:KEYWORD_ENGINE_TOOLTIPS.avgCompetitorSponsoredRank',
    'Average sponsored rank for the indexed comparison ASINs, excluding the highlighted ASIN. This is how well the ads for your searched ASINs are ranking for this keyword on average.'
  ),
  relativeOrganicPosition: t(
    'constants:KEYWORD_ENGINE_TOOLTIPS.relativeOrganicPosition',
    'The organic rank of the highlighted ASIN relative to the other ASINs. For example, if the highlighted ASIN ranks better than all the competitors then the rank will be 1.'
  ),
  relativeSponsoredPosition: t(
    'constants:KEYWORD_ENGINE_TOOLTIPS.relativeSponsoredPosition',
    'The sponsored rank of the highlighted ASIN relative to the other ASINs. For example, if the highlighted ASIN ranks better than all the competitors then the rank will be 1.'
  ),
  wordCount: t(
    'constants:KEYWORD_ENGINE_TOOLTIPS.wordCount',
    'Number of individual words in the keyword phrase.'
  ),
  noData: {
    category: t(
      'constants:KEYWORD_ENGINE_TOOLTIPS.noData.category',
      'No category available.'
    ),
    avgGiveaway: t(
      'constants:KEYWORD_ENGINE_TOOLTIPS.noData.avgGiveaway',
      'No giveaway data available.'
    ),
    noAmazonSearchVolume: t(
      'constants:KEYWORD_ENGINE_TOOLTIPS.noData.noAmazonSearchVolume',
      'No search volume available.'
    ),
    noJungleSearchVolume: t(
      'constants:KEYWORD_ENGINE_TOOLTIPS.noData.noJungleSearchVolume',
      'No search volume available.'
    ),
    exactSuggestedBidMedian: t(
      'constants:KEYWORD_ENGINE_TOOLTIPS.noData.exactSuggestedBidMedian',
      'No estimated PPC data available.'
    ),
    broadSuggestedBidMedian: t(
      'constants:KEYWORD_ENGINE_TOOLTIPS.noData.broadSuggestedBidMedian',
      'No estimated PPC data available.'
    ),
    easeOfRankingScore: t(
      'constants:KEYWORD_ENGINE_TOOLTIPS.noData.easeOfRankingScore',
      'No ranking score available.'
    ),
    keywordsTracked: t(
      'constants:KEYWORD_ENGINE_TOOLTIPS.noData.keywordsTracked',
      'No keywords tracked.'
    )
  },
  includeKeywordsKeywordSearch: t(
    'constants:KEYWORD_ENGINE_TOOLTIPS.includeKeywordsKeywordSearch',
    'Refine your results by narrowing them down to only include keywords that also contain the terms you specify. Example: Searching for "umbrella" + "black" shows results for black umbrellas.'
  ),
  includeKeywordsAsinSearch: t(
    'constants:KEYWORD_ENGINE_TOOLTIPS.includeKeywordsAsinSearch',
    'Expand your results by including additional keywords that match the terms you specify. Example: Adding “black” to an ASIN search includes keywords containing "black".'
  )
}
